import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Spin } from 'antd';

import './config/yupConfig';
import './config/i18n';
import 'antd/dist/antd.css';
import '~/styles/override.scss';
import theme from '~/styles/theme';
import { AuthProvider } from '~/contexts/auth';

import GlobalStyles from './styles/global';
import Routes from './routes';
import history from './services/history';

function App() {
  return (
    <Suspense fallback={<Spin className="page-loader" />}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <GlobalStyles />
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
