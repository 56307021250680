import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;

  background: #fff;

  height: 120px;
  .ant-menu .ant-menu-light .ant-menu-root .ant-menu-horizontal {
    background: #fff !important;
  }
`;
