import { Col, Row, message, Alert, Image, Modal, Collapse } from 'antd';
import { Select, Checkbox, Input } from 'formik-antd';
import { Formik, Form, isEmptyArray } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import Webcam from 'react-webcam';
import SignatureCanvas from 'react-signature-canvas';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import errorHandler from '~/Utils/errorHandler';
import { decrypt } from '~/Utils';

import Button from '~/components/Button';
import api from '~/services/api';
import FormActions from '~/components/Form/FormActions';

const { TextArea } = Input;
const { Panel } = Collapse;
const checkFunciona = [
  {
    label: 'Freio de pé',
    value: 'freio_pe',
    key: 'freio_pe',
  },
  {
    label: 'Freio de estacionamento',
    value: 'freio_estacionamento',
    key: 'freio_estacionamento',
  },
  {
    label: 'Motor de partida',
    value: 'motor_partida',
    key: 'motor_partida',
  },
  {
    label: 'Limpador de parabrisa',
    value: 'limpador_parabrisa',
    key: 'limpador_parabrisa',
  },
  {
    label: 'Lavador de Parabrisa',
    value: 'lavador_parabrisa',
    key: 'lavador_parabrisa',
  },
  {
    label: 'Buzina',
    value: 'buzina',
    key: 'buzina',
  },
  {
    label: 'Farois',
    value: 'farois',
    key: 'farois',
  },
  {
    label: 'Pisca Alerta',
    value: 'pisca_alerta',
    key: 'pisca_alerta',
  },
  {
    label: 'Setas Dianteiras',
    value: 'setas_dianteiras',
    key: 'setas_dianteiras',
  },
  {
    label: 'Setas Traseiras',
    value: 'setas_traseiras',
    key: 'setas_traseiras',
  },

  {
    label: 'Luz de freio',
    value: 'luz_freio',
    key: 'luz_freio',
  },
  {
    label: 'Luz de ré',
    value: 'luz_re',
    key: 'luz_re',
  },
  {
    label: 'Luz da placa',
    value: 'luz_placa',
    key: 'luz_placa',
  },
  {
    label: 'Indicadores de painel',
    value: 'indicadores_painel',
    key: 'indicadores_painel',
  },
  {
    label: 'Cinto de segurança',
    value: 'cinto_seguranca',
    key: 'cinto_seguranca',
  },
  {
    label: 'Fechamento de janelas',
    value: 'fechamento_janelas',
    key: 'fechamento_janelas',
  },
  {
    label: 'Trava das portas',
    value: 'travas_portas',
    key: 'travas_portas',
  },
  {
    label: 'Alarme',
    value: 'alarme',
    key: 'alarme',
  },
];

const checkPossui = [
  {
    label: 'Triângulo de advertência',
    value: 'triangulo_advertencia',
    key: 'triangulo_advertencia',
  },
  {
    label: 'Macaco',
    value: 'macaco',
    key: 'macaco',
  },
  {
    label: 'Chave de roda',
    value: 'chave_roda',
    key: 'chave_roda',
  },
];

const checkCondicoes = [
  {
    label: 'Condição Pneu',
    value: 'condicao_pneu',
    key: 'condicao_pneu',
  },
  {
    label: 'Pneu Estepe',
    value: 'pneu_estepe',
    key: 'pneu_estepe',
  },
];

const checkAvarias = [
  {
    label: 'Vidros',
    value: 'vidros',
    key: 'vidros',
  },
  {
    label: 'Portas',
    value: 'portas',
    key: 'portas',
  },
  {
    label: 'Para-choque dianteiro',
    value: 'parachoque_diant',
    key: 'parachoque_diant',
  },
  {
    label: 'Para-choque traseiro',
    value: 'parachoque_tras',
    key: 'parachoque_tras',
  },
  {
    label: 'Lataria',
    value: 'lataria',
    key: 'lataria',
  },
  {
    label: 'Espelhos retrovisores',
    value: 'espelhos_retrovisor',
    key: 'espelhos_retrovisor',
  },
  {
    label: 'Bancos',
    value: 'bancos',
    key: 'bancos',
  },
  {
    label: 'Teto',
    value: 'teto',
    key: 'teto',
  },
  {
    label: 'Quebra Sol',
    value: 'quebra_sol',
    key: 'quebra_sol',
  },
];

const checkNivel = [
  {
    label: 'Nível de óleo',
    value: 'nivel_oleo',
    key: 'nivel_oleo',
  },
  {
    label: 'Nível de fluído de freio',
    value: 'fluido_freio',
    key: 'fluido_freio',
  },
  {
    label: 'Nível de água',
    value: 'nivel_agua',
    key: 'nivel_agua',
  },
];
const checkEmDia = [
  {
    label: 'Habilitação',
    value: 'habilitacao',
    key: 'habilitacao',
  },
  {
    label: 'Manuteção Preventiva',
    value: 'manut_preven',
    key: 'manut_preven',
  },
  {
    label: 'Vazamentos',
    value: 'vazamentos',
    key: 'vazamentos',
  },

  {
    label: 'Calibracao Pneu',
    value: 'calibracao_pneu',
    key: 'calibracao_pneu',
  },
];

function Checklist({ handleCancel, setConfirmLoading, fetchRecords, idVeiculo, idCont, valuesEdit }) {
  const { t } = useTranslation();
  const history = useHistory();

  // IMAGENS
  const [imageUrl, setImageUrl] = useState();
  const [showImage, setShowImage] = useState(true);
  const [imageUrl2, setImageUrl2] = useState();
  const [showImage2, setShowImage2] = useState(true);
  const [imageUrl3, setImageUrl3] = useState();
  const [showImage3, setShowImage3] = useState(true);
  const [imageUrl4, setImageUrl4] = useState();
  const [showImage4, setShowImage4] = useState(true);
  const [imageUrl5, setImageUrl5] = useState();
  const [showImage5, setShowImage5] = useState(true);
  const [imageUrl6, setImageUrl6] = useState();
  const [showImage6, setShowImage6] = useState(true);
  const [imageUrl7, setImageUrl7] = useState();
  const [showImage7, setShowImage7] = useState(true);
  const [imageUrl8, setImageUrl8] = useState();
  const [showImage8, setShowImage8] = useState(true);
  const [imageUrl9, setImageUrl9] = useState();
  const [showImage9, setShowImage9] = useState(true);
  const [imageUrl10, setImageUrl10] = useState();
  const [showImage10, setShowImage10] = useState(true);

  const [motorist, setMotorist] = useState([]);
  const [sigURl, setSigURL] = useState(null);
  const [states, setStates] = useState([]);
  const [cidades, setCidades] = useState([]);

  const sigCanvas = useRef();
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setSigURL(URL);
  };

  const address =
    JSON.parse(decrypt(localStorage.getItem('@App:needs'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:needs')));

  const entradaHandler = async (values) => {
    if (!imageUrl || !imageUrl2 || !imageUrl3 || !imageUrl4 || !imageUrl5 || !imageUrl6 || !imageUrl7 || !imageUrl8) {
      message.error('Imagens são obrigatórias');
      return false;
    }
    if (!sigURl) {
      message.error('Assinatura é obrigatória');
      return false;
    }
    setConfirmLoading(true);
    try {
      values.checklist = {};
      const checkboxes = checkFunciona.concat(checkCondicoes, checkAvarias, checkNivel, checkPossui, checkEmDia);

      checkboxes.map((item) => {
        let x = true;
        if (item.value in values) {
          x = false;
        }
        Object.defineProperty(values.checklist, item.value, {
          value: x,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        return null;
      });
      values.checklist.estado = values.estado;
      values.checklist.cidade = values.cidade;
      values.veiculos_id = Number(idVeiculo);
      values.checklist.km_inicial = values.km_inicial;
      values.checklist.km_final = values.km_final;
      values.checklist.obs_gerais = values.obs_gerais;

      values.checklist.assinatura = sigURl;

      if (idCont) {
        if (!values.checklist.km_final) {
          message.error('Km final é obrigatório');
          return false;
        }

        await api.put(`/controle/${idCont}`, values);
      } else {
        await api.post('/controle', values);
      }
      await api.get(`veiculos/hard/${idVeiculo}`);

      const base64_link = {
        traseira: imageUrl,
        dianteira: imageUrl2,
        lateral_esquerda: imageUrl3,
        lateral_direita: imageUrl4,
        painel_interno: imageUrl5,
        bancos_frontais: imageUrl6,
        banco_traseiro: imageUrl7,
        porta_malas_aberto: imageUrl8,
        primeira_opcional: imageUrl9,
        segunda_opcional: imageUrl10,
      };

      await api.post(`/controle/imagens`, base64_link);
      message.success(t('messages:success'));
      fetchRecords();
      handleCancel();
      sigCanvas.current.clear();
      history.push('/');
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
    fetchRecords();
    return null;
  };

  const selecionaCidade = (estado) => {
    states.map((item) => {
      if (item.sigla === estado) {
        setCidades(item.cidades);
      }
      return null;
    });
  };
  const motorista = async () => {
    try {
      const { data } = await api.get('/getlogin');
      setMotorist(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setStates(address.cities.estados);
    motorista();
    valuesEdit.checkAvarias = [];
    valuesEdit.checkCondicoes = [];
    valuesEdit.checkFunciona = [];
    valuesEdit.checkPossui = [];
    valuesEdit.checkNivel = [];
    valuesEdit.checkEmDia = [];
    valuesEdit.checklists.map((item) => {
      if (!isEmptyArray(checkNivel.filter((role) => role.value === item))) {
        valuesEdit.checkNivel.push(item);
      }
      if (!isEmptyArray(checkPossui.filter((role) => role.value === item))) {
        valuesEdit.checkPossui.push(item);
      }
      if (!isEmptyArray(checkFunciona.filter((role) => role.value === item))) {
        valuesEdit.checkFunciona.push(item);
      }
      if (!isEmptyArray(checkCondicoes.filter((role) => role.value === item))) {
        valuesEdit.checkCondicoes.push(item);
      }
      if (!isEmptyArray(checkAvarias.filter((role) => role.value === item))) {
        valuesEdit.checkAvarias.push(item);
      }
      if (!isEmptyArray(checkEmDia.filter((role) => role.value === item))) {
        valuesEdit.checkAvarias.push(item);
      }
      return null;
    });
    delete valuesEdit.checklists;
  }, []);

  const videoConstraints = {
    facingMode: 'environment',
  };

  const SignupSchema = Yup.object().shape({
    km_final: Yup.number()
      .required(t('Campo Obrigatório'))
      .moreThan(Yup.ref('km_inicial'), 'O valor final deve ser maior que o valor inicial'),
    estado: Yup.string().required(t('Campo Obrigatório')),
    cidade: Yup.string().required(t('Campo Obrigatório')),
  });

  const SignupSchema2 = Yup.object().shape({
    km_inicial: Yup.number().required(t('Campo Obrigatório')),
    estado: Yup.string().required(t('Campo Obrigatório')),
    cidade: Yup.string().required(t('Campo Obrigatório')),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={valuesEdit}
        validationSchema={idCont ? SignupSchema : SignupSchema2}
        validateOnBlur={false}
        onSubmit={entradaHandler}
        validateOnChange
      >
        {({ values, errors, resetForm }) => (
          <Form>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Select
                      style={{ width: '90%' }}
                      id="people_id"
                      name="people_id"
                      component="select"
                      defaultValue={motorist.id}
                      disabled
                      aria-label="Selecionar motorista:"
                    >
                      <Select.Option key={motorist.id} value={motorist.id}>
                        {motorist.name}
                      </Select.Option>
                    </Select>
                    {errors.people_id && (
                      <Alert style={{ width: '90%' }} message={errors.people_id} type="error" showIcon />
                    )}
                  </Col>
                  {idCont ? (
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Input
                        name="km_final"
                        style={{ width: '90%' }}
                        type="number"
                        placeholder="Km do veículo"
                        aria-label="Km final:"
                      />
                      {errors.km_final && (
                        <Alert style={{ width: '90%' }} message={errors.km_final} type="error" showIcon />
                      )}
                    </Col>
                  ) : (
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Input
                        name="km_inicial"
                        style={{ width: '90%' }}
                        type="number"
                        disabled={valuesEdit.km_inicial}
                        placeholder="Km do veículo"
                        aria-label="Km inicial:"
                      />
                      {errors.km_inicial && (
                        <Alert style={{ width: '90%' }} message={errors.km_inicial} type="error" showIcon />
                      )}
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <label htmlFor="estado">Selecione o estado:</label>
                    <Select
                      style={{ width: '90%' }}
                      id="estado"
                      name="estado"
                      component="select"
                      defaultValue="Selecione o Estado"
                      onChange={selecionaCidade}
                      aria-label="Estado:"
                    >
                      {states.map((item) => (
                        <Select.Option key={item.sigla} value={item.sigla}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                    {errors.estado && <Alert style={{ width: '90%' }} message={errors.estado} type="error" showIcon />}
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <label htmlFor="cidade">Selecione a cidade:</label>
                    <Select
                      style={{ width: '90%' }}
                      id="cidade"
                      name="cidade"
                      component="select"
                      defaultValue="Selecione a Cidade"
                      disabled={isEmptyArray(cidades)}
                      aria-label="Cidade:"
                    >
                      {cidades.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                    {errors.cidade && <Alert style={{ width: '90%' }} message={errors.cidade} type="error" showIcon />}
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <TextArea
                  style={{ width: '90%' }}
                  name="obs_gerais"
                  autoSize={{
                    minRows: 3,
                    maxRows: 8,
                  }}
                  aria-label="Observação Geral:"
                />
              </Col>
            </Row>
            <br />
            <p>Assinale os itens:</p>
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Panel header="Não funcionam" key="1">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkFunciona"
                      options={checkFunciona}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkFunciona &&
                        values.checkFunciona.map((item) => {
                          const value = checkFunciona.filter((check) => check.value === item);
                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Veículo não possui" key="2">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkPossui"
                      options={checkPossui}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkPossui &&
                        values.checkPossui.map((item) => {
                          const value = checkPossui.filter((check) => check.value === item);
                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Condições ruins" key="3">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkCondicoes"
                      options={checkCondicoes}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkCondicoes &&
                        values.checkCondicoes.map((item) => {
                          const value = checkCondicoes.filter((check) => check.value === item);
                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Com avarias" key="4">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkAvarias"
                      options={checkAvarias}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkAvarias &&
                        values.checkAvarias.map((item) => {
                          const value = checkAvarias.filter((check) => check.value === item);

                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Não estão no nível" key="5">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkNivel"
                      options={checkNivel}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkNivel &&
                        values.checkNivel.map((item) => {
                          const value = checkNivel.filter((check) => check.value === item);
                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Não estão em dia" key="6">
                <Row>
                  <Col span={12}>
                    <Checkbox.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      name="checkEmDia"
                      options={checkEmDia}
                    />
                  </Col>
                  <Col span={12}>
                    <table border="1px solid black" style={{ padding: '5%' }}>
                      {values &&
                        values.checkEmDia &&
                        values.checkEmDia.map((item) => {
                          const value = checkEmDia.filter((check) => check.value === item);
                          return (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: '#F2F2F2' }}>{value[0].label}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input maxLength={255} name={item} id={item} aria-labelledby={item} />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                    </table>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <br />
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto traseira</span>
                <br />
                {showImage ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage(false)}
                  >
                    {imageUrl ? (
                      <Image src={imageUrl} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage(true)}
                    visible={!showImage}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl(imageSrc);
                            setShowImage(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto dianteira</span>
                <br />
                {showImage2 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage2(false)}
                  >
                    {imageUrl2 ? (
                      <Image src={imageUrl2} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage2(true)}
                    visible={!showImage2}
                    style={{ height: '100%' }}
                    footer={[]}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl2(imageSrc);
                            setShowImage2(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto lateral esquerda</span>
                <br />
                {showImage3 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage3(false)}
                  >
                    {imageUrl3 ? (
                      <Image src={imageUrl3} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage3(true)}
                    visible={!showImage3}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl3(imageSrc);
                            setShowImage3(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto lateral direita</span>
                <br />
                {showImage4 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage4(false)}
                  >
                    {imageUrl4 ? (
                      <Image src={imageUrl4} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage4(true)}
                    visible={!showImage4}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl4(imageSrc);
                            setShowImage4(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto painel interno</span>
                <br />
                {showImage5 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage5(false)}
                  >
                    {imageUrl5 ? (
                      <Image src={imageUrl5} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage5(true)}
                    visible={!showImage5}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl5(imageSrc);
                            setShowImage5(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto bancos frontais</span>
                <br />
                {showImage6 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage6(false)}
                  >
                    {imageUrl6 ? (
                      <Image src={imageUrl6} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage6(true)}
                    visible={!showImage6}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl6(imageSrc);
                            setShowImage6(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto banco traseiro</span>
                <br />
                {showImage7 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage7(false)}
                  >
                    {imageUrl7 ? (
                      <Image src={imageUrl7} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage7(true)}
                    visible={!showImage7}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl7(imageSrc);
                            setShowImage7(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Foto porta-malas</span>
                <br />
                {showImage8 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage8(false)}
                  >
                    {imageUrl8 ? (
                      <Image src={imageUrl8} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage8(true)}
                    visible={!showImage8}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl8(imageSrc);
                            setShowImage8(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <br />
              <hr />
              <hr />

              <br />
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Avarias</span>
                <br />
                {showImage9 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage9(false)}
                  >
                    {imageUrl9 ? (
                      <Image src={imageUrl9} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage9(true)}
                    visible={!showImage9}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl9(imageSrc);
                            setShowImage9(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <span>Avarias</span>
                <br />
                {showImage10 ? (
                  <button
                    style={{ backgroundColor: 'white', padding: '20px', margin: '10px' }}
                    type="button"
                    onClick={() => setShowImage10(false)}
                  >
                    {imageUrl10 ? (
                      <Image src={imageUrl10} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Fotografia</div>
                      </div>
                    )}
                  </button>
                ) : (
                  <Modal
                    closable
                    onCancel={() => setShowImage10(true)}
                    visible={!showImage10}
                    style={{ height: '100%' }}
                    footer={null}
                  >
                    <Webcam
                      videoConstraints={videoConstraints}
                      audio={false}
                      height="100%"
                      screenshotFormat="image/jpeg"
                      width="100%"
                    >
                      {({ getScreenshot }) => (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setImageUrl10(imageSrc);
                            setShowImage10(true);
                          }}
                        >
                          Tirar foto
                        </Button>
                      )}
                    </Webcam>
                  </Modal>
                )}
              </Col>

              <br />
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <span>Assinatura</span>

                <Row>
                  <SignatureCanvas
                    required
                    ref={sigCanvas}
                    backgroundColor="grey"
                    penColor="black"
                    onEnd={create}
                    canvasProps={{
                      height: 200,
                      width: 340,
                      className: 'sigCanvas',
                    }}
                  />
                  <br />
                  <button
                    type="button"
                    onClick={() => {
                      sigCanvas.current.clear();
                      setSigURL(null);
                    }}
                  >
                    Limpar
                  </button>
                </Row>
              </Col>
            </Row>
            <br />

            <Row style={{ marginTop: '10px' }}>
              <FormActions>
                <Button style={{ float: 'right' }} key="submit" color="info" type="submit">
                  Salvar
                </Button>
                <Button
                  key="back"
                  onClick={() => {
                    handleCancel();
                    resetForm();
                    setImageUrl(null);
                    setImageUrl2(null);
                  }}
                >
                  Cancelar
                </Button>
              </FormActions>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default Checklist;
