/* arrumar permissão de veiculos e relatorios eslint-disable react/prop-types */

import React from 'react';
import { Menu as AntMenu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Button from '~/components/Button';

import { decrypt } from '~/Utils/index';

import { MenuGlobalStyle, Nav } from './styles';

export default function Menu() {
  const { t } = useTranslation();

  const { permissions: permissionList, roles: roleList } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const user =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const checkAuth = (permission, role) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        return false;
      }
    } else {
      return true;
    }
    return auth;
  };

  const MenuItem = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) ? <AntMenu.Item {...props}>{children}</AntMenu.Item> : null;

  const SubMenu = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) && <AntMenu.SubMenu {...props}>{children}</AntMenu.SubMenu>;

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  const renderMenu = (item) => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    }
    if (type === 'sub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={renderSubMenuTitle(item.label)}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    if (type === 'rightSub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={item.label}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    return (
      <MenuItem key={item.key} permission={item.permission}>
        {item.click ? (
          <Button onClick={item.click}>
            {item.icon && item.icon}
            {item.label}
          </Button>
        ) : (
          <Link to={item.url}>
            {item.icon && item.icon}
            {item.label}
          </Link>
        )}
      </MenuItem>
    );
  };

  const menuItems = [
    {
      type: 'item',
      key: 'dash',
      permission: '@dashboard/view',
      label: user.type === 'Admin' ? 'Dashboard' : t('menus:dash'),
      url: '/',
    },
    {
      type: 'sub',
      key: 'registrations',
      permission: '@registrations/view',
      hidden: !(user.type === 'Admin'),
      label: t('menus:registrations'),
      children: [
        {
          type: 'item',
          key: 'reg.users',
          permission: '@user/view',
          label: t('menus:reg_users'),
          url: '/usuarios',
        },
        // {
        //   type: 'item',
        //   key: 'set.roles',
        //   permission: '@superAdmin',
        //   label: t('menus:set_roles'),
        //   url: '/roles',
        // },
        {
          type: 'divider',
          key: 'set_div_1',
        },
        {
          type: 'item',
          key: 'reg.veiculos',
          permission: '@user/view',
          label: t('menus:veiculos'),
          url: '/veiculos',
        },
      ],
    },
    {
      type: 'item',
      key: 'relatorios',
      permission: '@dashboard/view',
      label: t('menus:relatorios'),
      hidden: !(user.type === 'Admin'),
      url: '/relatorios',
    },
  ].filter((item) => item.hidden !== true);

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['cadastros']}>
          {menuItems.map((item) => renderMenu(item))}
        </AntMenu>
      </Nav>
    </>
  );
}
