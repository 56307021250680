import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from '~/pages/Home';
import Roles from '~/pages/Roles';
import Person from '~/pages/Person';
import PersonForm from '~/pages/Person/form';
import Veiculos from '~/pages/Veiculos';
import EntradaSaida from '~/pages/Veiculos/EntradaSaida';
import Relatorios from '~/pages/Relatorios';
import Errors from '~/pages/Errors/e404';

export default function OtherRoutes() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route exact path="/roles" component={Roles} />
      <Route exact path="/usuarios" component={Person} />
      <Route exact path="/veiculos" component={Veiculos} />
      <Route exact path="/relatorios" component={Relatorios} />
      <Route exact path="/controle/:idVeiculo" component={EntradaSaida} />
      <Route exact path="/usuarios/novo" component={PersonForm} />
      <Route exact path="/usuarios/:id/edicao" component={PersonForm} />
      <Route path="/404" component={Errors} />
    </BrowserRouter>
  );
}
