/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin, Col, Popconfirm, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';

import { decrypt } from '~/Utils/index';

const initialValues = {
  name: '',
  enable: true,
};

export default function ProfileForm({ visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);

  const [loading, setLoading] = useState(false);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleReset = async () => {
    setLoading(true);
    try {
      await api.post('/people/gifts/reset');
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      await api
        .get('/people/gifts/export', {
          method: 'get',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `pessoas_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.warning('Nenhum registro encontrado.');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.put('/gifts', values);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/gifts');
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const GiftSchema = Yup.object().shape({
    name: Yup.string().required(),
    enable: Yup.boolean().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={GiftSchema}
    >
      {({ errors, values, isSubmitting, setValues, submitForm, resetForm }) => (
        <Modal
          title={t('screens:gifts.title')}
          onCancel={onClose}
          style={{ maxWidth: '950px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="70%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <div style={{ position: 'absolute', display: 'flex' }}>
                <Button color="info" style={{ marginRight: '0.5rem' }} onClick={handleExport} loading={loading}>
                  Exportar
                </Button>
                <Popconfirm
                  title={t('messages:confirmResetPeopleData')}
                  okText={t('messages:yes')}
                  onConfirm={handleReset}
                  cancelText={t('messages:no')}
                >
                  <Button loading={loading} color="info">
                    Resetar registros
                  </Button>
                </Popconfirm>
              </div>
              <ModalFooter
                type={permissions && !permissions.includes('@people/edit') && 'info'}
                onOk={submitForm}
                loading={loading || isSubmitting}
                onCancel={onClose}
                okColor='info'
              />
            </>
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <Row>
                    <FormControl
                      cols={{ xs: 11 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:gifts.data.name')}
                      required
                    >
                      <Input name="name" disabled={permissions && !permissions.includes('@people/edit')} />
                    </FormControl>
                    <FormControl cols={{ xs: 2 }} field="enable" label={t('screens:gifts.data.enable')}>
                      <Switch
                        name="enable"
                        checked={values.enable}
                        onChange={(e) => setValues({ ...values, enable: e })}
                        disabled={permissions && !permissions.includes('@people/edit')}
                      />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
