import { Row, Col, Button } from 'antd';
import { Select } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { Formik, Form, isEmptyArray } from 'formik';
import './styles.css';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

function Pesquisa({ onQuery }) {
  const formLayout = 'inline';
  const [placas, setPlacas] = useState([]);
  const [modelos, setModelos] = useState([]);

  const fetchRecords = async (modelo) => {
    try {
      const { data } = await api.get('/veiculos', {
        params: {
          modelo,
        },
      });
      setPlacas(data.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchModelos = async () => {
    try {
      const { data } = await api.get(`/veiculos/modelos`);
      setModelos(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchModelos();
  }, []);

  const formItemLayout = {
    labelcol: { span: 8 },
    wrappercol: { span: 14 },
  };

  const buttonItemLayout = {
    wrappercol: { span: 14, offset: 4 },
  };

  return (
    <Formik {...formItemLayout} layout={formLayout} onSubmit={() => {}} initialValues={{ layout: formLayout }}>
      {({ values, resetForm }) => (
        <Form>
          <Row gutter={[10, 12]} justify="left" align="middle">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="modelo">Modelo:</label>

              <br />
              <Select
                name="modelo"
                value={values.modelo}
                style={{
                  width: '100%',
                  marginTop: '5px',
                }}
                onChange={(value) => {
                  fetchRecords(value);
                }}
                aria-label="Modelo:"
                placeholder="Insira o modelo"
              >
                <Select.Option value="" key="todos" name="todos">
                  Todos
                </Select.Option>
                {modelos &&
                  modelos.map((v) => (
                    <Select.Option value={v} key={v} name={v}>
                      {v}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="placa">Placa:</label>
              <br />

              <Select
                name="placa"
                value={values.placa}
                disabled={isEmptyArray(placas)}
                listHeight={200}
                style={{
                  width: '100%',
                  marginTop: '5px',
                }}
                aria-label="Placa:"
                placeholder="Insira a placa"
              >
                {placas &&
                  placas.map((v) => (
                    <Select.Option value={v.placa} key={v.id} name={v.id}>
                      {v.placa}
                    </Select.Option>
                  ))}
              </Select>
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="periodo">Período:</label>

              <br />
              <Select
                name="periodo"
                value={values.periodo}
                style={{
                  width: '100%',
                  marginTop: '5px',
                }}
                aria-label="Periodo:"
                placeholder="Escolha o periodo"
              >
                <Select.Option value="" key="todos" name="todos">
                  Todos
                </Select.Option>
                <Select.Option value="1" key="1" name="1">
                  Em período de vistoria
                </Select.Option>
                <Select.Option value="0" key="0" name="0">
                  Fora do período de vistoria
                </Select.Option>
              </Select>
            </Col>

            {/* <Col style={{ marginTop: '18px' }} {...buttonItemLayout}> */}
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              style={{ marginTop: '18px', alignContent: 'center' }}
              {...buttonItemLayout}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <Button
                  type="primary"
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    onQuery(values);
                  }}
                >
                  Pesquisar
                </Button>
                <Button
                  type="reset"
                  style={{ marginLeft: '15px' }}
                  onClick={
                    // reset Form values and query
                    () => {
                      onQuery({ placa: '', modelo: '', periodo: '' });
                      resetForm();
                    }
                  }
                >
                  Limpar
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default Pesquisa;
