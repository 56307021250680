import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';

import { Container } from './styles';

export default function Logo({ width, margin, isLogin }) {
  return (
    <Container className={`tanks-logo ${isLogin ? 'from-login' : 'from-sys'}`} to="/" margin={margin}>
      <Image width={width} src="/Tanks-Cor.jpg" preview={false} />
    </Container>
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  margin: PropTypes.string,
  isLogin: PropTypes.bool,
};

Logo.defaultProps = {
  width: 250,
  margin: '0',
  isLogin: false,
};
