import React from 'react';
import PropTypes from 'prop-types';
import Button from '~/components/Button';

import { Footer } from './styles';

export function ModalFooter({ loading, onCancel, onOk, okText, cancelText, cancelColor, okColor, type }) {
  if (type === 'info') {
    return (
      <Footer>
        <Button loading={loading} color={cancelColor} onClick={onCancel}>
          {cancelText}
        </Button>
      </Footer>
    );
  }
  return (
    <Footer>
      <Button loading={loading} color={okColor} onClick={onOk}>
        {okText}
      </Button>
      <Button loading={loading} color={cancelColor} onClick={onCancel} style={{ marginLeft: '8px' }}>
        {cancelText}
      </Button>
    </Footer>
  );
}

ModalFooter.propTypes = {
  loading: PropTypes.bool,
  cancelText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  cancelColor: PropTypes.string,
  okColor: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

ModalFooter.defaultProps = {
  loading: false,
  cancelText: 'Cancelar',
  okText: 'Salvar',
  onCancel: null,
  onOk: null,
  cancelColor: 'default',
  okColor: 'primary',
  type: 'default',
};
