/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, message } from 'react';
import { Modal, Image, Row, Col, Descriptions, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCamera, FaFileExcel } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Box from '~/components/Box';
import { Table, TableActions } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import { decrypt } from '~/Utils/index';

import Pesquisa from './pesquisa';
import './styles.css';

export default function Relatorios() {
  const { t } = useTranslation();
  const [showImages, setShowImages] = useState(false);
  const [images, setImages] = useState([]);

  const user =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const [recordData, setRecordData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [meta, setMeta] = useState({});
  const [obs, setObs] = useState([]);

  const fetchRecords = async () => {
    setConfirmLoading(true);
    try {
      const { data } = await api.get(`/controle`, {
        params: {
          motorista: user.type === 'Admin' ? searchTerm.name : user.name,
          start_date: searchTerm.dateDe,
          end_date: searchTerm.dateAte,
          veiculos: searchTerm.veiculo,
        },
      });
      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const fetchObs = async () => {
    setConfirmLoading(true);
    try {
      const { data } = await api.get(`/observacoes`);
      setObs(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const fetchImages = async (id) => {
    setConfirmLoading(true);
    try {
      const { data } = await api.get(`/controle/imagens/${id}`);
      setImages(data);
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const handleChangePage = async (page) => {
    setConfirmLoading(true);
    try {
      const response = await api.get(`/controle?page=${page}`, {
        params: {
          motorista: searchTerm.motorista,
          start_date: searchTerm.dateDe,
          end_date: searchTerm.dateAte,
          veiculos: searchTerm.veiculo,
        },
      });
      const { data } = response;
      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const handleExport = async () => {
    setConfirmLoading(true);
    try {
      await api
        .get('/controle/export/all', {
          method: 'get',
          responseType: 'blob',
          params: {
            motorista: user.type === 'Admin' ? searchTerm.name : user.name,
            start_date: searchTerm.dateDe,
            end_date: searchTerm.dateAte,
            veiculos: searchTerm.veiculo,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `controle_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.warning('Nenhum registro encontrado.');
      }
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const handleExportAvarias = async (veiculo_id, checklist_id) => {
    setConfirmLoading(true);
    try {
      await api
        .get(`/veiculos/export/${veiculo_id}/${checklist_id}`, {
          method: 'get',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `avarias_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.warning('Nenhum registro encontrado.');
      }
      errorHandler(error);
    }
    setConfirmLoading(false);
  };

  const expandedRowRender = (record) => {
    console.log(record);
    console.log(obs);
    const obsItems = obs.filter((obsItem) => record.checklist_id === obsItem.checklist_id);

    if (obsItems.length > 0) {
      return (
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Descriptions
                  title={<PageTitle level={5}>AVARIAS DO VEÍCULO</PageTitle>}
                  layout="vertical"
                  bordered
                  colon={false}
                  extra={
                    <Button
                      loading={confirmLoading}
                      color="info"
                      onClick={() => handleExportAvarias(record.veiculos.id, record.checklist_id)}
                    >
                      <FaFileExcel />
                      {t('screens:relatorios.btnNew')}
                    </Button>
                  }
                  size="small"
                  column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                >
                  {obsItems.map((obsItem) =>
                    obsItem.where ? (
                      <Descriptions.Item label={<strong>COM PROBLEMA</strong>} key={obsItem.id}>
                        <p key={obsItem.where}>{obsItem.where}</p>
                      </Descriptions.Item>
                    ) : (
                      <Descriptions.Item label={<strong>NÃO HÁ AVARIAS NO VEÍCULO</strong>} key={obsItem.id} />
                    )
                  )}
                </Descriptions>
              </Space>
            </Col>
          </Row>
        </Space>
      );
    }

    return null; // Retorna null caso a condição não seja satisfeita
  };

  useEffect(() => {
    fetchRecords();
    fetchObs();
  }, [searchTerm]);

  const tableColumns = [
    {
      title: t('screens:relatorios.data.id'),
      dataIndex: 'id',
      key: 'id',
      responsive: ['lg'],
    },
    {
      title: t('screens:relatorios.data.date'),
      dataIndex: 'saida',
      key: 'saida',
      responsive: ['lg'],
    },
    {
      title: t('screens:relatorios.data.entrega'),
      dataIndex: 'entrada',
      key: 'entrada',
      responsive: ['lg'],
    },
    {
      title: t('screens:relatorios.data.motorista'),
      dataIndex: 'checklist_users',
      key: 'checklist_users',
    },
    {
      title: t('screens:relatorios.data.veiculo'),
      dataIndex: 'veiculos_placa',
      key: 'veiculos_placa',
    },
    {
      title: t('screens:relatorios.data.km'),
      dataIndex: 'checklist_km_inicial',
      key: 'checklist_km_inicial',
      responsive: ['lg'],
    },
    {
      title: t('screens:relatorios.data.kmFinal'),
      dataIndex: 'checklist_km_final',
      key: 'checklist_km_final',
      responsive: ['lg'],
    },
    {
      title: t('screens:relatorios.data.kmRodado'),
      dataIndex: 'km_rodados',
      key: 'km_rodados',
    },
    {
      title: t('screens:usuario.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Button
            style={{ margin: 'auto' }}
            title={t('Ver imagens')}
            onClick={() => {
              setShowImages(true);
              fetchImages(record.id);
            }}
          >
            <FaCamera />
          </Button>
        </TableActions>
      ),
    },
  ];

  return (
    <>
      <DefaultLayout>
        <span className="desktop">
          <PageTitle
            className="desktop"
            title={`Relatório de uso do veículo por ${user.type === 'Admin' ? 'motorista' : user.name}`}
          >
            <Button loading={confirmLoading} color="info" onClick={() => handleExport()}>
              <FaFileExcel />
              {t('screens:relatorios.btnNew')}
            </Button>
          </PageTitle>
        </span>
        <span className="mobile">
          <PageTitle className="desktop" title={t('Relatórios veículos')}>
            <Button loading={confirmLoading} color="info" onClick={() => handleExport()}>
              <FaFileExcel />
              {t('screens:relatorios.btnNew')}
            </Button>
          </PageTitle>
        </span>

        <Box>
          <Pesquisa onQuery={setSearchTerm} />
        </Box>
        <Box>
          <Table
            pagination={false}
            rowKey="id"
            loading={confirmLoading}
            dataSource={recordData}
            columns={tableColumns}
            expandable={{
              expandedRowRender: (record) =>
                obs.some((obsItem) => record.checklist_id === obsItem.checklist_id)
                  ? expandedRowRender(record)
                  : undefined,
              rowExpandable: (record) => obs.some((obsItem) => record.checklist_id === obsItem.checklist_id),
            }}
            onExpand={(expanded, record) => {
              if (expanded === true) {
                const whereValues = obs
                  .filter((obsItem) => record.checklist_id === obsItem.checklist_id)
                  .map((obsItem) => obsItem.where);
                console.log(whereValues); // Mostra os valores de where no console
                // Faça o que quiser com os valores de whereValues, por exemplo, setar em algum estado
              }
            }}
          />
          <Pagination
            onChange={(value) => {
              handleChangePage(value);
            }}
            meta={meta}
          />
        </Box>
        <Modal
          closable
          visible={showImages}
          style={{ height: '100%' }}
          onCancel={() => setShowImages(false)}
          width={1000}
          footer={null}
        >
          <Row>
            {images &&
              images.map((image) => (
                <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                  <Image src={image.url} />
                </Col>
              ))}
          </Row>
        </Modal>
      </DefaultLayout>
    </>
  );
}
