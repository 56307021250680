/* eslint-disable no-unused-vars */
import { Select, Input } from 'formik-antd';
import { Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import './styles.css';

import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

function Pesquisa({ onQuery }) {
  const formLayout = 'inline';

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const [name, setName] = useState();
  const fetchRecords = async () => {
    try {
      const { data } = await api.get(`/users`);
      setName(data.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <Formik {...formItemLayout} layout={formLayout} initialValues={{ type: '', active: '', name: '' }}>
      {({ values, resetForm }) => (
        <Form>
          <Row gutter={[10, 12]} justify="left" align="middle">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 5, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="nome">Nome:</label>
              <br />
              <Input style={{ width: '100%' }} value={values.nome} name="name" />
              {/* <Select style={{ width: '100%' }} value={values.nome} placeholder="Insira o nome" name="name">
                <Select.Option value="" key={-1} name="Todos">
                  Todos
                </Select.Option>
                {name != null &&
                  name.map((item) => (
                    <Select.Option key={item.id} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select> */}
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 5, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="type">Nível:</label>
              <br />

              <Select style={{ width: '100%' }} value={values.type} name="type">
                <Select.Option value="" key={2} name="Todos">
                  Todos
                </Select.Option>
                <Select.Option value="Admin" key={1} name="Admin">
                  Administrador
                </Select.Option>
                <Select.Option value="User" key={0} name="User">
                  Motorista
                </Select.Option>
              </Select>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 5, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <label htmlFor="active">Status:</label>
              <br />

              <Select style={{ width: '100%' }} value={values.active} placeholder="Todos" name="active">
                <Select.Option value="" key={2} name="Todos">
                  Todos
                </Select.Option>
                <Select.Option value={1} key={1} name="Ativo">
                  Ativo
                </Select.Option>
                <Select.Option value={0} key={0} name="Inativo">
                  Inativo
                </Select.Option>
              </Select>
            </Col>
            <Col
              sm={{ span: 24, offset: 0 }}
              md={{ span: 5, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              style={{ marginTop: '18px', alignContent: 'center' }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <Button
                  style={{ marginLeft: '8px' }}
                  type="primary"
                  onClick={() => {
                    onQuery(values);
                  }}
                >
                  Pesquisar
                </Button>
                <Button
                  style={{ marginLeft: '15px' }}
                  type="reset"
                  onClick={() => {
                    onQuery({ search: null });
                    resetForm();
                  }}
                >
                  Limpar
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default Pesquisa;
