import styled, { css } from 'styled-components';
import { Upload } from 'antd';

export const Container = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50%;
  }
`;

export const Box = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  small.caption {
    color: #4f96c2;
    font-weight: 500;
  }
  ${(props) =>
    props.noPadding &&
    css`
      padding: unset;
      align-items: center;
    `}
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Value = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  color: #303030;
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
`;

export const SmallTotalValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #303030;
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: unset;
`;

export const SmallMetaPercentageValue = styled.small`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #303030;
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: unset;
`;

export const TotalValue = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  color: #303030;
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: unset;
`;

export const MetaPercentageValue = styled.small`
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
`;

export const Data = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #303030;
`;

export const Text = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4f96c2;
  position: relative;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
`;

export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-top: 15px;
  color: ${(props) => props.theme.color2};
`;

export const Balance = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 56px;
  text-align: center;

  color: #303030;
`;
