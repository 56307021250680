import React from 'react';
import PropTypes from 'prop-types';

import { Container, ContainerMobile } from './styles';

export default function Header({ isMobile, children }) {
  return isMobile ? <ContainerMobile>{children}</ContainerMobile> : <Container>{children}</Container>;
}

Header.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

Header.defaultProps = {
  isMobile: false,
};
