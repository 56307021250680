import React from 'react';
import PropTypes from 'prop-types';

import { Container, SectionHeader } from './styles';

export default function PageTitle({ title, subtitle, onBack, children, size }) {
  return size === 2 ? (
    <SectionHeader>
      <span>{title}</span>
      {children}
    </SectionHeader>
  ) : (
    <Container onBack={onBack} title={title} subTitle={subtitle}>
      {children}
    </Container>
  );
}

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  onBack: PropTypes.func,
  size: PropTypes.number,
};

PageTitle.defaultProps = {
  title: null,
  children: <></>,
  subtitle: null,
  onBack: null,
  size: 1,
};
