import styled from 'styled-components';

export const Container = styled.header`
  display: flex;

  background: #4b9fe9;

  padding: 0 30px;
  height: 88px;

  h1 {
    padding-top: 1.2rem;
    font-size: 40px;
    font-weight: 600;
    margin: 0 auto;
    color: rgb(255 255 255);
    justify-content: center;
  }
`;
