import { DatePicker, Radio, Input } from 'formik-antd';
import { Button, Row, Col } from 'antd';
import React from 'react';
import { Formik, Form } from 'formik';
import '../styles.css';

function Pesquisa({ onQuery }) {
  const formLayout = 'inline';

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Formik {...formItemLayout} layout={formLayout} initialValues={{ layout: formLayout }}>
      {({ values, resetForm }) => (
        <Form>
          <Row>
            <Col xs={8} xl={6}>
              <Input style={{ width: '94%' }} placeholder="Insira o nome" name="motorista" aria-label="Motorista:" />
            </Col>
            <Col xs={8} xl={4}>
              <DatePicker placeholder="Selecione a data" format="DD/MM/YYYY" style={{ width: '95%' }} name="dateDe" aria-label="De:" />
            </Col>
            <Col xs={8} xl={4}>
              <DatePicker placeholder="Selecione a data" format="DD/MM/YYYY" style={{ width: '95%' }} name="dateAte" aria-label="Até:" />
            </Col>
            <Col xs={8} xl={6}>
              <Radio.Group name="radio" style={{ marginTop: '20px' }} value={values.radio}>
                <Radio value={0}>Todas</Radio>
                <Radio value={1}>Pendentes</Radio>
                <Radio value={2}>Realizadas</Radio>
              </Radio.Group>
            </Col>

            <Col xs={8} md={6} lg={2} xl={2} style={{ marginTop: '20px' }}>
              <Button
                type="primary"
                onClick={() => {
                  onQuery(values);
                }}
              >
                Pesquisar
              </Button>
            </Col>
            <Col xs={8} md={6} lg={2} xl={2} style={{ marginTop: '20px' }}>
              <Button type="reset" onClick={() => resetForm()}>
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default Pesquisa;
