/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import EnviromentHeader from '~/pages/_partials/EnviromentHeader';

import Menu from '~/pages/_partials/Menu';
import Header from '~/pages/_partials/Header';
import Footer from '~/pages/_partials/Footer';
import Top from '~/pages/_partials/Top';
import Logo from '~/components/Logo';
import SmallMenu from '~/pages/_partials/SmallMenu';
import { Container } from './styles';

export default function FullLayout({ children }) {
  const { Content } = Layout;
  const enviroment = process.env.REACT_APP_ENVIROMENT;
  const [windowWidth, setWindowWidth] = useState(null);

  const sizeOfThings = () => {
    const { innerWidth } = window;
    setWindowWidth(innerWidth);
  };

  window.addEventListener('resize', () => {
    sizeOfThings();
  });

  useEffect(() => {
    sizeOfThings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="layout">
      {enviroment !== 'production' && <EnviromentHeader />}
      {windowWidth < 500 ? (
        <Header isMobile>
          <Logo width={100} />
          <SmallMenu />
        </Header>
      ) : (
        <>
          <Top />
          <Header>
            <Menu />
          </Header>
        </>
      )}

      <Content>{children}</Content>
      <Footer />
    </Container>
  );
}

FullLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
