import React from 'react';

import { Container } from './styles';

export default function EnviromentHeader() {
  return (
    <Container>
      <h1>AMBIENTE DE HOMOLOGAÇÃO</h1>
    </Container>
  );
}
