import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${(props) => props.size};
  font-style: italic;
  font-weight: bold;
  color: ${(props) => props.color || props.theme.color1};
  margin: ${(props) => props.margin};

  transition: transform 0.4s;

  &:hover {
    transform: scale(1.08);
    & span {
      color: ${(props) => props.theme.color3} !important;
    }
  }

  &.from-login {
    flex-direction: column;
    span {
      font-size: 24px;
      color: ${(props) => props.theme.color3};
      margin: 0 auto;
      font-style: normal;
      font-weight: 600;
    }
  }
  &.from-sys {
    .wrapper {
      display: flex;
      margin-left: 5px;
    }
    span {
      margin-top: auto;
      margin-bottom: 5px;
      font-size: 18px;
      color: ${(props) => props.theme.color8};
    }
  }
`;
