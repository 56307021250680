import React, { useState, useEffect } from 'react';
import { FaPlus, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { message, Row, Popconfirm, Switch } from 'antd';
import { useHistory } from 'react-router-dom';

import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import { Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import GiftForm from './gift';
import './styles.css';
import Pesquisa from './pesquisa';

export default function Person() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [meta, setMeta] = useState({});
  const [typeUser, setTypeUser] = useState();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get('/users?page=1', {
        params: {
          search: searchTerm || null,
        },
      });

      const { data } = response;
      setRecordData(data.data);
      setMeta(data.meta);
      // message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/users?page=${page}`, {
        params: {
          search: searchTerm || null,
        },
      });
      const { data } = response;

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/usuarios/novo');
  };

  const handleEdit = async (id) => {
    history.push(`/usuarios/${id}/edicao`);
  };

  const handleDelete = async (record) => {
    setLoading(true);
    try {
      await api.delete(`/users/${record}`);
      handleSearch();
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchUser = async () => {
    try {
      const { data } = await api.get('/getlogin');
      setTypeUser(data.type);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    handleSearch();
    fetchUser();
  }, [searchTerm]);

  const changeDisp = async (record) => {
    setLoading(true);
    console.log(record);
    try {
      record.active = !record.active;
      await api.put(`/users/${record.id}`, record);
      message.success(t('messages:success'));
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
    handleSearch();
  };

  const tableColumns = [
    {
      title: t('screens:usuario.data.name'),
      key: 'name',
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: t('screens:usuario.data.nivel'),
      key: 'nivel',
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => <span>{record.type === 'Admin' ? 'Administrador' : 'Motorista'}</span>,
    },
    {
      title: t('screens:usuario.data.telefone'),
      key: 'telefone',
      dataIndex: 'telefone',
      responsive: ['lg', 'xl', 'xxl'],
    },
    // {
    //   title: t('screens:usuario.data.email'),
    //   key: 'email',
    //   dataIndex: 'email',
    //   responsive: ['lg', 'xl', 'xxl'],
    // },
    {
      title: 'Usuários',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>{record.name}</p>
          {/* span with color gray */}
          <p>
            <span style={{ color: 'gray' }}>{record.type === 'Admin' ? 'Administrador' : 'Motorista'}</span>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <Switch
                size="small"
                style={{ marginTop: '6px', marginRight: '12px' }}
                name="disp"
                checked={record.active}
                onChange={() => changeDisp(record)}
              />
              {typeUser === 'Admin' && (
                <>
                  <Button style={{ margin: 'auto' }} title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
                    <FaPencilAlt style={{ color: 'green' }} />
                  </Button>
                  <Popconfirm
                    title={t('messages:confirmDelete')}
                    onConfirm={() => {
                      handleDelete(record.id);
                    }}
                    okText={t('messages:yes')}
                    cancelText={t('messages:no')}
                  >
                    <Button title={t('messages:delete')}>
                      <FaRegTrashAlt style={{ color: 'red' }} />
                    </Button>
                  </Popconfirm>
                </>
              )}
            </TableActions>
          </div>
        </div>
      ),
    },
    // {
    //   title: t('screens:usuario.data.status'),
    //   key: 'status',
    //   responsive: ['lg'],
    //   render: (text, record) => <span>{record.active === 1 ? <Tag color="green">Ativo</Tag> : <Tag color="red">Inativo</Tag>}</span>,
    // },
    {
      title: t('screens:usuario.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '130px',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <TableActions>
          <Switch
            size="small"
            style={{ marginTop: '6px', marginRight: '12px' }}
            name="disp"
            checked={record.active}
            onChange={() => changeDisp(record)}
          />
          {typeUser === 'Admin' && (
            <>
              <Button style={{ margin: 'auto' }} title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
                <FaPencilAlt style={{ color: 'green' }} />
              </Button>
              <Popconfirm
                title={t('messages:confirmDelete')}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt style={{ color: 'red' }} />
                </Button>
              </Popconfirm>
            </>
          )}
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Row style={{ margin: '10px' }}>
        <PageTitle title={t('screens:usuario.title')}>
          {typeUser === 'Admin' && (
            <Can permission="@usuario/edit">
              <Button style={{ display: 'inline-block' }} color="info" loading={loading} onClick={handleNew}>
                <FaPlus />
                {t('screens:usuario.btnNew')}
              </Button>
            </Can>
          )}
        </PageTitle>
      </Row>
      <Box>
        <Pesquisa onQuery={setSearchTerm} />
        {console.log('searchterm', searchTerm)}
      </Box>
      <Box>
        <Table pagination={false} rowKey="id" loading={loading} dataSource={recordData} columns={tableColumns} />
        <Pagination
          onChange={(value) => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
      <GiftForm visible={visible} onClose={() => setVisible(false)} />
    </DefaultLayout>
  );
}
