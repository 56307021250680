/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { Col, Row, Menu as AntMenu } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Logo from '~/components/Logo';
import UserInfo from '~/pages/_partials/UserInfo';
import Profile from '~/pages/Profile/form';
import { useAuth } from '~/contexts/auth';
import { decrypt } from '~/Utils/index';
import { Container } from './styles';

export default function Top() {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const { name, id } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const context = useAuth();

  function handleSignOut() {
    context.Logout();
  }

  function handleProfile() {
    setShowProfile(true);
  }

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  return (
    // <Container>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-around" align="middle" style={{ backgroundColor: '#fff' }}>
      <Col flex={2}>
        {/* centralizar o logo */}
        <div style={{ float: 'left' }}>
          <Logo height="100%" margin="0 0 0 40px" />
        </div>
      </Col>
      <Col flex={3}>
        {/* alinha userinfo a direita */}
        <div style={{ float: 'right' }}>
          <UserInfo>
            <AntMenu mode="horizontal" defaultSelectedKeys={['perfil']}>
              <AntMenu.SubMenu key="perfil" title={renderSubMenuTitle(name)}>
                <AntMenu.Item key="profile" onClick={handleProfile}>
                  <AiOutlineUser /> {t('menus:user_profile')}
                </AntMenu.Item>
                <AntMenu.Item key="logout" onClick={handleSignOut}>
                  <AiOutlineLogout /> {t('menus:user_logout')}
                </AntMenu.Item>
              </AntMenu.SubMenu>
            </AntMenu>
            <Profile userID={id} visible={showProfile} onClose={() => setShowProfile(false)} />
          </UserInfo>
        </div>
      </Col>
    </Row>
    // </Container>
  );
}
