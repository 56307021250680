import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Remember from '~/pages/Password/Remember';
import Reset from '~/pages/Password/Reset';
import Login from '../pages/Login';

export default function SignRoutes({ other }) {
  return (
    <BrowserRouter>
      <Route exact path="/:token?" component={Login} />
      <Route exact path="/senha/link" component={Remember} />
      <Route exact path="/senha/reset/:token" component={Reset} />
      {other}
    </BrowserRouter>
  );
}

SignRoutes.propTypes = {
  other: PropTypes.oneOfType([PropTypes.any]),
};

SignRoutes.defaultProps = {
  other: null,
};
