import { Modal, Popconfirm, message, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaRegTrashAlt, FaClipboardList } from 'react-icons/fa';
import { isEmptyArray } from 'formik';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Box from '~/components/Box';
import '../styles.css';
import { TableActions, Table } from '~/components/Table';
import Pagination from '~/components/Pagination';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Pesquisa from './pesquisa';
import Checklist from './forms';

export default function EntradaSaida() {
  const { t } = useTranslation();
  const { idVeiculo } = useParams();

  // modal
  const [open, setOpen] = useState(false);
  const [openEnt, setOpenEnt] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [meta, setMeta] = useState({});
  const [searchTerm, setSearchTerm] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [valuesEdit, setValuesEdit] = useState({});
  const [viewButton, setViewButton] = useState(true);
  const [idCont, setIdCont] = useState();
  const [typeUser, setTypeUser] = useState();
  const [user, setUser] = useState();
  const [userName, setUserName] = useState({});

  const showModal = async () => {
    const { data } = await api.get(`/veiculos/relatorios/${idVeiculo}`);
    const edit = {
      people_id: user,
      checklists: [],
    };
    if (Object.keys(data).length !== 0) {
      edit.km_inicial = data.checklist.km_final;
      data.obs.map((item) => {
        Object.defineProperty(edit, item.where, {
          value: item.input,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        edit.checklists.push(item.where);
        return null;
      });
    }
    setValuesEdit(edit);
    setOpen(true);
  };

  const handleChangePage = async (page) => {
    setConfirmLoading(true);
    try {
      const response = await api.get(`/controle/${idVeiculo}?page=${page}`, {
        params: {
          motorista: searchTerm.motorista || null,
          dataDe: searchTerm.dateDe || null,
          dataAte: searchTerm.dateAte || null,
          radio: searchTerm.radio || null,
        },
      });
      const { data } = response;
      setRecordData(data.data);
      setMeta(data.meta);
    } catch (erro) {
      errorHandler(erro);
    }
    setConfirmLoading(false);
  };

  const seeButton = (record) => {
    if (record !== null) {
      if (record[record.length - 1].entrada === null) {
        setViewButton(false);
        return null;
      }
      setViewButton(true);
    }
    return null;
  };

  const fetchRecords = async () => {
    setConfirmLoading(true);
    try {
      const { data } = await api.get(`/controle/${idVeiculo}`, {
        params: {
          motorista: searchTerm.motorista,
          dateDe: searchTerm.dateDe,
          dateAte: searchTerm.dateAte,
          radio: searchTerm.radio,
        },
      });
      if (isEmptyArray(data.data)) {
        setConfirmLoading(false);
        return null;
      }
      setRecordData(data.data);
      setMeta(data.meta);
      seeButton(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
    return null;
  };

  const fetchUser = async () => {
    try {
      const { data } = await api.get('/getlogin');
      setUser(data.id);
      setUserName(data.name);
      setTypeUser(data.type);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleEdit = async (record) => {
    setConfirmLoading(true);
    try {
      const { data } = await api.get(`/checklist/${record.checklist_id}`);

      const inicial = {
        people_id: record.checklist_users,
        checklists: Object.values(data.colunas),
        condicao_pneus: Object.values(data.colunas).indexOf('condicao_pneu') >= 0 ? 0 : 1,
        nivel_oleos: Object.values(data.colunas).indexOf('nivel_oleo') >= 0 ? 0 : 1,
        nivel_aguas: Object.values(data.colunas).indexOf('nivel_agua') >= 0 ? 0 : 1,
        pneu_estepes: Object.values(data.colunas).indexOf('pneu_estepe') >= 0 ? 0 : 1,
        fluido_freios: Object.values(data.colunas).indexOf('fluido_freio') >= 0 ? 0 : 1,
        km_final: record.checklist_km_final,
        km_inicial: record.checklist_km_inicial,
        obs_gerais: data.obs_gerais,
      };
      data.obs.map((item) => {
        Object.defineProperty(inicial, item.where, {
          value: item.input,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        return null;
      });
      console.log(inicial);
      setValuesEdit(inicial);
      fetchRecords();
    } catch (error) {
      errorHandler(error);
    }
    setOpenEnt(true);
    setConfirmLoading(false);
  };

  const handleDelete = async (id) => {
    setConfirmLoading(true);
    try {
      await api.delete(`/controle/${id}/imagens`);
      await api.delete(`controle/${id}`);
      fetchRecords();
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setConfirmLoading(false);
  };
  useEffect(() => {
    fetchRecords();
    fetchUser();
    // handleSearch();
  }, [searchTerm]);

  const tableColumns = [
    {
      title: t('screens:veiculos.entradaSaida.data.id'),
      dataIndex: 'id',
      responsive: ['lg'],
      key: 'id',
    },
    {
      title: t('screens:veiculos.entradaSaida.data.date'),
      dataIndex: 'saida',
      responsive: ['lg'],
      key: 'saida',
    },
    {
      title: t('screens:veiculos.entradaSaida.data.motorista'),
      dataIndex: 'checklist_users',
      key: 'nome',
    },
    {
      title: t('screens:veiculos.entradaSaida.data.entrega'),
      dataIndex: 'entrada',
      key: 'entrada',
      responsive: ['lg'],
    },
    {
      title: t('screens:veiculos.entradaSaida.data.kmInicial'),
      dataIndex: 'checklist_km_inicial',
      key: 'km_inicial',
      responsive: ['lg'],
    },
    {
      title: t('screens:veiculos.entradaSaida.data.kmFinal'),
      dataIndex: 'checklist_km_final',
      key: 'checklist_km_final',
      responsive: ['lg'],
    },
    {
      title: t('screens:veiculos.entradaSaida.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          {!record.entrada && typeUser === 'Admin' && (
            <div style={{ textAlign: 'center' }}>
              <Button
                size="small"
                title="Desmobilizar"
                style={{ marginRight: '5px', width: '80%' }}
                onClick={() => {
                  setIdCont(record.id);
                  handleEdit(record);
                }}
              >
                {record.disponivel ? (
                  <span style={{ color: '#197ACF' }}>Mobilizar</span>
                ) : (
                  <span style={{ color: '#197ACF' }}>Desmobilizar</span>
                )}

                <FaClipboardList />
              </Button>
            </div>
          )}
          {record.checklist_users === userName && typeUser !== 'Admin' && !record.entrada && (
            <div style={{ textAlign: 'center' }}>
              <Button
                size="small"
                title="Desmobilizar"
                style={{ marginRight: '5px', width: '80%' }}
                onClick={() => {
                  setIdCont(record.id);
                  handleEdit(record);
                }}
              >
                {record.disponivel ? (
                  <span style={{ color: '#197ACF' }}>Mobilizar</span>
                ) : (
                  <span style={{ color: '#197ACF' }}>Desmobilizar</span>
                )}

                <FaClipboardList />
              </Button>
            </div>
          )}
          {typeUser === 'Admin' && (
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => {
                handleDelete(record.id);
              }}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          )}
        </TableActions>
      ),
    },
  ];

  return (
    <>
      <DefaultLayout>
        <span className="desktop">
          <PageTitle className="desktop" title={t('screens:veiculos.entradaSaida.title')}>
            {viewButton && (
              <Button onClick={showModal} loading={confirmLoading} color="info">
                <FaPlus />
                {t('screens:veiculos.entradaSaida.btnNew')}
              </Button>
            )}
          </PageTitle>
        </span>
        <span className="mobile">
          <Row>
            <PageTitle title={t('screens:veiculos.entradaSaida.title')} />
          </Row>
          <Row style={{ marginBottom: '0.5rem' }}>
            {viewButton && (
              <Button onClick={showModal} loading={confirmLoading} color="info">
                <FaPlus />
                {t('screens:veiculos.entradaSaida.btnNew')}
              </Button>
            )}
          </Row>
        </span>

        <Modal
          closable
          title="Checklist Saída de Veículo"
          visible={open}
          confirmLoading={confirmLoading}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          width={1000}
          footer={[]}
        >
          <Checklist
            idVeiculo={idVeiculo}
            valuesEdit={valuesEdit}
            fetchRecords={fetchRecords}
            setConfirmLoading={setConfirmLoading}
            handleCancel={() => setOpen(false)}
            entradaId={null}
          />
        </Modal>
        <Modal
          closable
          title="Checklist Entrada de Veículo"
          visible={openEnt}
          confirmLoading={confirmLoading}
          onClose={() => setOpenEnt(false)}
          onCancel={() => setOpenEnt(false)}
          width={1000}
          footer={[]}
        >
          <Checklist
            idVeiculo={idVeiculo}
            valuesEdit={valuesEdit}
            idCont={idCont}
            fetchRecords={fetchRecords}
            setConfirmLoading={setConfirmLoading}
            handleCancel={() => setOpenEnt(false)}
          />
        </Modal>

        <Box>
          <Pesquisa onQuery={(value) => setSearchTerm(value)} />
        </Box>
        <Box>
          <Table
            pagination={false}
            rowKey="id"
            loading={confirmLoading}
            dataSource={recordData}
            columns={tableColumns}
          />
          <Pagination
            onChange={(value) => {
              handleChangePage(value);
            }}
            meta={meta}
          />
        </Box>
      </DefaultLayout>
    </>
  );
}
