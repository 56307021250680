/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Input, Checkbox } from 'formik-antd';
import * as Yup from 'yup';
import Logo from '~/components/Logo';
import Button from '~/components/Button';
import BaseLayout from '~/pages/_layouts/base';
import FormControl from '~/components/Form/FormControl';

import { useAuth } from '~/contexts/auth';
import { crypt, decrypt } from '~/Utils';
import { Container, ButtonGlobalStyle } from './styles';

const storedValues =
  localStorage.getItem('@App:login') &&
  JSON.parse(decrypt(localStorage.getItem('@App:login'))) !== null &&
  JSON.parse(decrypt(localStorage.getItem('@App:login')));

const initialValues = storedValues || { remember: false, email: null, password: null };

export default function Login(props) {
  const { t } = useTranslation();
  const context = useAuth();

  const { history } = props;
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  // console.log('props', props);
  // console.log('context', context);
  // console.log('history', history);

  // eslint-disable-next-line react/destructuring-assignment
  // const { token } = props.match.params;

  if (context.signed) {
    history.push('/dashboard');
  }

  if (token) {
    history.push(`/senha/reset/${token}`);
    // return <Redirect to={`/senha/reset/${token}`} />;
  }

  const handleSubmit = (values) => {
    context.Login(values);
  };

  const schema = Yup.object().shape({
    email: Yup.string().email('Insira um e-mail válido').required('campo obrigatório'),
    password: Yup.string().required('campo obrigatório'),
  });

  const handleChange = async ({ email, password, remember }) => {
    if (remember) {
      const cryptedLogin = crypt(JSON.stringify({ email, password, remember }));
      localStorage.setItem('@App:login', cryptedLogin);
    } else {
      localStorage.removeItem('@App:login');
    }
  };

  return (
    <BaseLayout>
      <ButtonGlobalStyle />
      {/* <Redirect to={`/password/reset/${token}`} /> */}
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ errors, values, setValues }) => (
          <Container>
            <Logo isLogin width={202} margin="0 0 40px 0" />
            <FormControl field="email" error={errors.email}>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                  handleChange({ ...values, email: e.target.value });
                }}
                placeholder={t('fields:login.email.placeholder')}
              />
            </FormControl>
            <FormControl field="password" error={errors.email}>
              <Input
                autoComplete="password"
                type="password"
                name="password"
                value={values.password}
                onChange={(e) => {
                  setValues({ ...values, password: e.target.value });
                  handleChange({ ...values, password: e.target.value });
                }}
                placeholder={t('fields:login.password.placeholder')}
              />
            </FormControl>

            <Checkbox
              className="remember-me"
              name="remember"
              checked={values.remember}
              onClick={(e) => {
                setValues({ ...values, remember: e.target.checked });
                handleChange({ ...values, remember: e.target.checked });
              }}
            >
              Lembrar Login
            </Checkbox>

            <Link to={{ pathname: '/senha/link' }} className="forgotPw">Esqueci minha senha</Link>
            {/* { token && <Redirect to={{ pathname: `/senha/reset/${token}` }} /> } */}
            {/* <Link to={{ pathname: `/senha/reset/${token}` }} className="forgotPw">password reset</Link> */}
            {/* {{ pathname: `/password/reset/${token}` }} */}
            <Button size="large" block type="submit" margin="40px 0 0 0" loading={context.loading} color="info">
              {t('fields:login.submit')}
            </Button>

          </Container>

        )}
      </Formik>
    </BaseLayout>
  );
}
