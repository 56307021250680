/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Menu as AntMenu } from 'antd';
import { GoGear } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars } from 'react-icons/fa';
import { MenuGlobalStyle, Nav } from './styles';
import { decrypt } from '../../../Utils';
import Button from '../../../components/Button';
import ProfileForm from '../../Profile/form';
import { useAuth } from '../../../contexts/auth';

export default function SmallMenu() {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);

  const { permissions: permissionList, roles: roleList } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const user =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const userName = user ? user.name : null;
  const userId = user ? user.id : null;
  const context = useAuth();

  function handleSignOut() {
    context.Logout();
  }

  const getClassName = (key) => {
    let name = null;
    if (key === 'profile') {
      name = 'menu-profile';
    } else if (key === 'logout') {
      name = 'menu-logout';
    }
    return name;
  };

  const checkAuth = (permission, role) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        return false;
      }
    } else {
      return true;
    }
    return auth;
  };

  const MenuItem = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) ? <AntMenu.Item {...props}>{children}</AntMenu.Item> : null;

  const SubMenu = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) && <AntMenu.SubMenu {...props}>{children}</AntMenu.SubMenu>;

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      {/* <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" /> */}
    </>
  );

  const renderMenu = (item) => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    }
    if (type === 'sub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={renderSubMenuTitle(item.label)}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    if (type === 'rightSub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={item.label}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    return (
      <MenuItem
        key={item.key}
        permission={item.permission} 
        className={getClassName(item.key)}
      >
        {item.click ? (
          item.key === 'profile' ? (
            <Button onClick={item.click} id={item.key}>
              {item.label}
              {item.icon && item.icon}
            </Button>
            ) : (
              <Button onClick={item.click} id={item.key}>
                {item.icon && item.icon}
                {item.label}
              </Button>
          )
        ) : (
          <Link to={item.url}>
            {item.icon && item.icon}
            {item.label}
          </Link>
        )}
      </MenuItem>
    );
  }; 

  const menuSubItems = [
    {
      type: 'item',
      key: 'profile',
      role: ['DEFAULT'],
      label: `${userName}`,
      icon: <GoGear style={{ marginBottom: '3px', marginLeft: '5px' }} />,
      click: () => setShowProfile(true),
    },
    {
      type: 'item',
      key: 'dash',
      permission: '@dashboard/view',
      label: user.type === 'Admin' ? 'Dashboard' : t('menus:dash'),
      url: '/',
    },
    {
      type: 'sub',
      key: 'registrations',
      permission: '@registrations/view',
      hidden: !(user.type === 'Admin'),
      label: t('menus:registrations'),
      children: [
        {
          type: 'item',
          key: 'reg.users',
          permission: '@user/view',
          label: t('menus:reg_users'),
          url: '/usuarios',
        },
        {
          type: 'divider',
          key: 'set_div_1',
        },
        {
          type: 'item',
          key: 'reg.veiculos',
          permission: '@user/view',
          label: t('menus:veiculos'),
          url: '/veiculos',
        },
      ],
    },
    {
      type: 'item',
      key: 'relatorios',
      permission: '@dashboard/view',
      label: t('menus:relatorios'),
      hidden: !(user.type === 'Admin'),
      url: '/relatorios',
    },
    {
      type: 'divider',
      key: 'set_div_2',
    },
    {
      type: 'item',
      key: 'logout',
      permission: ['DEFAULT'],
      label: t('menus:user_logout'),
      click: handleSignOut,
    },
  ].filter((item) => item.hidden !== true);

  const menuItems = [
    {
      type: 'sub',
      key: 'smallmenu',
      permission: '@dashboard/view',
      label: <FaBars />,
      children: menuSubItems,
    }
    ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['rdv']} style={{ marginLeft: 'auto' }}>
          {menuItems.map(item => renderMenu(item))}
        </AntMenu>
      </Nav>
      <ProfileForm userID={userId} visible={showProfile} onClose={() => setShowProfile(false)} />
    </>
  );
}
