import i18next from 'i18next';
import { message } from 'antd';

const online = navigator.onLine;

const showMessage = (text) => {
  message.error(text);
};

const handleErrorStatus = (response, e422) => {
  const { status } = response;

  if (status === 422) {
    let { errors } = response.data.msg;
    if (errors && e422) {
      return errors;
    }
    errors = response.data.errors;
    if (errors.rg) {
      showMessage(i18next.t(errors.rg[0]));
      return errors.rg[0];
    }
    if (errors.email) {
      showMessage(i18next.t(errors.email[0]));
      return errors.email[0];
    }
    if (errors.cpf) {
      showMessage(i18next.t(errors.cpf[0]));
      return errors.cpf[0];
    }
    if (errors.carteira_motorista) {
      showMessage(i18next.t("O campo carteira de motorista já está sendo utilizado."));
      return errors.carteira_motorista[0];
    }
    showMessage(i18next.t('messages:invalidData'));
  } else if (status === 404) {
    window.location.replace('/404');
  } else if (status === 403) {
    showMessage(i18next.t('messages:unauthorized'));
  } else if (status === 401) {
    showMessage(i18next.t('messages:authError'));
  } else if (status === 429) {
    showMessage(i18next.t('messages:tooManyRequests'));
  } else if (status === 417) {
    showMessage(i18next.t('messages:expectationFailed'));
  } else if (status === 500) {
    showMessage(i18next.t('messages:randomError'));
  } else if (status === 406) {
    showMessage(i18next.t('messages:notAcceptable'));
  } else if (status === 409) {
    const errors = response.data.message;
    if (errors){
    showMessage(errors);
    }else{
      showMessage(i18next.t('Desmobilize seu veículo antes de fazer outra mobilização'));
    }
  }

  return {};
};

const errorHandler = (exception, e422 = true) => {
  const { response } = exception;

  if (online) {
    if (response) {
      return handleErrorStatus(response, e422);
    }
    if (exception.message === 'Network Error') {
      showMessage(i18next.t('messages:backendOff'));
    } else {
      showMessage(i18next.t('messages:randomError'));
    }
  } else {
    showMessage(i18next.t('messages:offline'));
  }

  return {};
};

export default errorHandler;
