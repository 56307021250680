/* eslint-disable no-unused-vars */
import { Input } from 'formik-antd';
import React from 'react';
import { Col, Row, Alert, Modal, message, Spin } from 'antd';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
// import Button from '~/components/Button';
// import FormActions from '~/components/Form/FormActions';

const { TextArea } = Input;

const newValues = {
  placa: '',
  modelo: '',
  disponivel: null,
  km_inicial: null,
  km_final: null,
  obs_gerais: null,
};

function VeiculoForm({ onAddVeiculo, inicialValues, handleCancel, visible, loading }) {
  const formLayout = 'inline';
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const SignupSchema = Yup.object().shape({
    placa: Yup.string().max(8, 'Muito longa!').required('campo obrigatório'),
    modelo: Yup.string().required('campo obrigatório'),
  });

  const formataPlaca = (event) => {
    let inputValue = event.target.value;
    // Remove todos os caracteres não alfanuméricos
    inputValue = inputValue.replace(/[^0-9A-Za-z]/g, '');

    // Remove todos os caracteres não são letras
    if (inputValue.length <= 3) {
      inputValue = inputValue.replace(/[^A-Za-z]/g, '').toUpperCase();
    } else if (inputValue.length === 4) {
      // Remove todos os caracteres não numéricos
      inputValue = `${inputValue.substring(0, 3)}${inputValue.replace(/[^0-9]/g, '')}`;
    } else if (inputValue.length === 5) {
      // Uma letra ou número (formato mercosul)
      inputValue = `${inputValue.substring(0, 4)}${inputValue.substring(4, 5).replace(/^[A-Za-z][0-9]{1}$/g, '')}`;
    } else if (inputValue.length >= 6) {
      // Remove todos os caracteres não numéricos
      inputValue = `${inputValue.substring(0, 5)}${inputValue.substring(5, 7).replace(/[^0-9]/g, '')}`;
    }
    if (inputValue.length > 7) {
      console.log('inputValue', inputValue);
      inputValue = inputValue.substring(0, 7);
    }
    return inputValue;
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={inicialValues || newValues}
      enableReinitialize
      onSubmit={onAddVeiculo}
      validationSchema={SignupSchema}
      {...formItemLayout}
      layout={formLayout}
    >
      {({ errors, isSubmitting, submitForm, resetForm, setFieldValue }) => (
        <Modal
          title={inicialValues.placa !== '' ? 'Adicionar Novo Veículo' : 'Editar Veículo'}
          onCancel={handleCancel}
          style={{ maxWidth: '650px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={handleCancel} />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={12} xl={12}>
                  <FormControl error={errors.placa} field="name" label="Placa" required>
                    <Input
                      name="placa"
                      id="placa"
                      style={{ width: '90%', textTransform: 'uppercase' }}
                      placeholder="Enter placa"
                      onChange={(e) => {
                        setFieldValue('placa', formataPlaca(e));
                      }}
                    />
                  </FormControl>
                </Col>
                <Col xs={12} xl={12}>
                  <FormControl cols={{ xs: 24 }} error={errors.modelo} field="modelo" label="Modelo" required>
                    <Input
                      name="modelo"
                      id="modelo"
                      style={{ width: '90%', textTransform: 'uppercase' }}
                      placeholder="Enter modelo"
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col xs={24} xl={24}>
                  Observação:
                  <TextArea
                    name="obs_gerais"
                    id="obs_gerais"
                    rows={4}
                    style={{ width: '100%', resize: 'none', borderRadius: '10px' }}
                    aria-label="Observação:"
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
export default VeiculoForm;
