import { DatePicker, Select } from 'formik-antd';
import { Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import './styles.css';

import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

const options = [];
function Pesquisa({ onQuery }) {
  const formLayout = 'inline';

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const [veiculos, setVeiculos] = useState();
  const [user, setUser] = useState();

  const fetchRecords = async () => {
    try {
      const { data } = await api.get(`/veiculos`);
      setVeiculos(data.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchUser = async () => {
    try {
      const { data } = await api.get('/getlogin');
      setUser(data.type);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await api.get('/users');
      data.data.map((pessoa) => options.push(pessoa));
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchRecords();
    fetchUser();
    fetchUsers();
  }, []);

  return (
    <Formik {...formItemLayout} layout={formLayout} initialValues={{ name: '', veiculo: '' }}>
      {({ values, resetForm }) => (
        <Form>
          <Row>
            {user === 'Admin' && (
              <Col xs={8} md={6} lg={5} xl={5}>
                <label htmlFor="name">Motorista:</label>
                <Select style={{ width: '95%' }} value={values.name} placeholder="Insira o nome" name="name">
                  <Select.Option value="" key={-1} name="Todos">
                    Todos
                  </Select.Option>
                  {options != null &&
                    options.map((item) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
            )}
            <Col xs={8} md={6} lg={5} xl={5}>
              <label htmlFor="veiculo">Veículo:</label>
              <Select
                name="veiculo"
                value={values.veiculo}
                style={{
                  width: '95%',
                }}
              >
                <Select.Option value="" key={-1} name="Todos">
                  Todos
                </Select.Option>
                {veiculos &&
                  veiculos.map((v) => (
                    <Select.Option value={v.placa} key={v.id} name={v.id}>
                      {v.placa}
                    </Select.Option>
                  ))}
              </Select>
            </Col>

            <Col xs={8} md={6} lg={5} xl={5}>
              <label htmlFor="dateDe">De:</label>
              <DatePicker format="DD/MM/YYYY" style={{ width: '95%' }} name="dateDe" aria-label="De:" />
            </Col>
            <Col xs={8} md={6} lg={5} xl={5}>
              <label htmlFor="dateAte">Até:</label>
              <DatePicker format="DD/MM/YYYY" style={{ width: '95%' }} name="dateAte" aria-label="Até:" />
            </Col>

            <Col xs={8} md={6} lg={2} xl={2} style={{ marginTop: '1%' }}>
              <Button
                type="primary"
                onClick={() => {
                  onQuery(values);
                }}
              >
                Pesquisar
              </Button>
            </Col>
            <Col xs={8} md={6} lg={2} xl={2} style={{ marginTop: '1%' }}>
              <Button
                type="reset"
                onClick={() => {
                  resetForm();
                  onQuery([]);
                }}
              >
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default Pesquisa;
