/* eslint-disable func-names */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Input, Form, Radio } from 'formik-antd';
import MaskedInput from 'antd-mask-input';
import { useTranslation } from 'react-i18next';
import { Spin, message, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro';
import 'moment/locale/pt-br';
import { FaCamera, FaRegTrashAlt } from 'react-icons/fa';
import Row from '~/components/Row';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import { decrypt, validateCpf } from '~/Utils/index';
import { Container, DivTitle, UploadStyle, ImagePreview } from './styles';
import './styles.css';

const initialValues = {
  name: '',
  cpf: '',
  rg: '',
  email: '',
  verified: false,
  type: 'User',
  carteira_motorista: '',
  active: true,
  telefone: '',
};

export default function Person({ match }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [file, setFile] = useState(null);
  const [deleteOldFile, setDeleteOldFile] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  const [userLoggedType, setUserLoggedType] = useState('User');
  const [telefone, setTelefone] = useState('');
  // IMAGENS

  const uploadButton = (
    <>
      <FaCamera size={24} />
      <div className="ant-upload-text">{t('messages:add')}</div>
    </>
  );
  const handleUpload = async () => {
    setChangedFile(true);
  };

  const beforeUpload = (avatar) => {
    const isJpgOrPng = avatar.type === 'image/jpeg' || avatar.type === 'image/jpg' || avatar.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = avatar.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor que 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    setLoading(true);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj, (image) => {
        setImageUrl(image);
        setFile(info.file);
      });
    }
    setLoading(false);
  };

  const uploadConfig = {
    name: 'file',
    action: handleUpload,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload,
    onChange: handleChange,
  };

  const setFieldError = (setErrors, msg, field) => {
    if (msg) {
      message.error(msg);
    }
    const errors = {};
    errors[field] = msg;
    setErrors(errors);
  };

  const handleCPF = (cpf, values, setErrors, setValues) => {
    if (!validateCpf(cpf)) {
      setFieldError(setErrors, 'CPF inválido', 'cpf');
      setValues({
        ...values,
        cpf: null,
      });
      return false;
    }

    return true;
  };

  const handleSave = async (values, { setErrors, setValues }) => {
    setLoading(true);
    const validCpf = values.cpf && handleCPF(values.cpf.replace(/[^0-9,]*/g, ''), values, setErrors, setValues);
    if (validCpf) {
      try {
        if (values.type !== 'Admin') {
          if (!telefone) {
            message.error('Telefone é obrigatório');
            setLoading(false);
            return;
          }
          values.telefone = telefone;
        }
        if (values.rg === '' || !values.rg) {
          delete values.rg;
        }
        if (values.type === 'Admin') {
          console.log('values.type = Admin', values);
          values.carteira_motorista = '';
          values.telefone = '';
        }

        if (values.carteira_motorista === '' || !values.carteira_motorista) delete values.carteira_motorista;
        if (values.password === '' || !values.password) delete values.password;
        if (values.id) {
          if (deleteOldFile === true) {
            await api.delete(`/files/${values.id}/receipt`);
            values.receipt_id = null;
          }
          await api.put(`/users/${values.id}`, values);

          if (file && file.originFileObj) {
            const files = new FormData();
            files.append('file', file.originFileObj);
            await api.post(`/files/${values.id}/receipt`, files);
          }
          if (changedFile === true) {
            if (imageUrl !== null) {
              const files = new FormData();
              files.append('file', file.originFileObj);
              await api.post(`/avatar`, files);
            }
          }
          message.success(t('messages:successUpdateUsuario'));
        } else {
          if (!values.password) {
            message.error('Senha é obrigatório');
            setLoading(false);
            return;
          }
          const { data } = await api.post('/users', values);
          if (changedFile === true) {
            if (imageUrl !== null) {
              const files = new FormData();
              files.append('file', file.originFileObj);
              await api.post(`/users/avatar/${data.id}`, files);
            }
          }
          message.success(t('messages:successUsuario'));
        }
        setFile(null);
        setDeleteOldFile(null);
        history.push(`/usuarios`);
      } catch (error) {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const handleBack = () => {
    history.push('/usuarios');
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { id } = match.params;
      if (id) {
        const { data } = await api.get(`/users/${id}`);
        if (data.type !== 'Admin') {
          setTelefone(data.telefone);
        }
        setImageUrl(data.avatar_url);
        setUserLoggedType(data.type);
        setRecordData(data);
      } else {
        setRecordData(initialValues);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecordData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifySchema = Yup.object().shape({
    name: Yup.string().required().min(10),
    email: Yup.string().email().required(),
    cpf: Yup.string().required(),
    active: Yup.boolean().required(),
    type: Yup.string().required(),
    carteira_motorista: Yup.string().test(
      'is-carteira-required',
      'Carteira Motorista is required for non-Admin users',
      function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        const userType = this.parent.type;
        if (userType === 'Admin') {
          return true;
        }
        return !!value;
      }
    ),
  });

  return (
    <DefaultLayout>
      <PageTitle
        title={t('screens:usuario.title')}
        subtitle={recordData.name ? `editando - ${recordData.name}` : null}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={verifySchema}
      >
        {({ errors, values, setErrors, setValues, setFieldValue }) => (
          <Spin spinning={loading}>
            <Form>
              <ConnectedFocusError />
              <Input type="hidden" name="id" />
              <Box>
                <div>
                  {/* AVATAR */}
                  <Row>
                    <Col xs={24} xl={24}>
                      <FormControl cols={{ xs: 24 }} required field="avatar" style={{ alignItems: 'center' }}>
                        <>
                          <UploadStyle />
                          {imageUrl ? (
                            <ImagePreview className="img-preview" size="100px" background={imageUrl}>
                              <div className="img-preview-wrapper">
                                <div
                                  aria-hidden="true"
                                  className="img-preview-delete"
                                  onClick={() => {
                                    setImageUrl(null);
                                    setFile(null);
                                    setChangedFile(true);
                                  }}
                                >
                                  <FaRegTrashAlt size={24} />
                                  {t('messages:delete')}
                                </div>
                                <div className="img-preview-file" />
                              </div>
                            </ImagePreview>
                          ) : (
                            <Container {...uploadConfig} size="100px">
                              <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
                            </Container>
                          )}
                        </>
                      </FormControl>
                    </Col>
                  </Row>

                  <Row>
                    {/* GRUPO 1 */}
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 20, offset: 2 }}
                      md={{ span: 20, offset: 2 }}
                      lg={{ span: 12, offset: 6 }}
                    >
                      <DivTitle>{t('screens:usuario.data.data')}</DivTitle>
                      <FormControl
                        error={errors.active}
                        cols={{ xs: 24 }}
                        field="active"
                        label={t('screens:usuario.data.active')}
                      >
                        <Radio.Group
                          name="active"
                          id="active"
                          value={values.active}
                          disabled={permissions && !permissions.includes('@usuario/edit')}
                          buttonStyle="solid"
                          defaultValue={1}
                        >
                          <Radio.Button name="active" value>
                            {t('messages:yes')}
                          </Radio.Button>
                          <Radio.Button name="active" value={0}>
                            {t('messages:no')}
                          </Radio.Button>
                        </Radio.Group>
                      </FormControl>
                      <FormControl
                        error={errors.type}
                        cols={{ xs: 24 }}
                        field="type"
                        label={t('screens:usuario.data.type')}
                      >
                        <Radio.Group
                          name="type"
                          defaultValue="User"
                          buttonStyle="solid"
                          onChange={(e) => {
                            if (e.target.value === 'Admin') {
                              console.log('e.target.value', e.target.value);
                              setFieldValue('carteira_motorista', '');
                              setTelefone('');
                            }
                          }}
                        >
                          <Radio.Button value="Admin">{t('Admin')}</Radio.Button>
                          <Radio.Button value="User">{t('Motorista')}</Radio.Button>
                        </Radio.Group>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.name}
                        field="name"
                        label={t('screens:usuario.data.name')}
                        required
                      >
                        <Input name="name" style={{ textTransform: 'uppercase' }} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.password}
                        field="password"
                        label={t('screens:usuario.data.password')}
                        required
                      >
                        <Input name="password" type="password" autoComplete="off" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.email}
                        field="email"
                        label={t('screens:usuario.data.email')}
                        required
                      >
                        <Input
                          name="email"
                          type="email"
                          style={{ textTransform: 'lowercase' }}
                          disabled={permissions && !permissions.includes('@usuario/edit')}
                        />
                        {/* <>{values.password}</> */}
                      </FormControl>
                      <FormControl cols={{ xs: 24 }} error={errors.rg} field="rg" label={t('screens:usuario.data.rg')}>
                        <Field name="rg">
                          {({ field }) => (
                            <MaskedInput mask="11.111.111-1" {...field} id="rg" placeholder="__.___.___-_" />
                          )}
                        </Field>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.cpf}
                        field="cpf"
                        label={t('screens:usuario.data.cpf')}
                        required
                      >
                        <Field name="cpf">
                          {({ field }) => (
                            <MaskedInput
                              mask="111.111.111-11"
                              {...field}
                              id="cpf"
                              onBlur={(e) => {
                                const cpf = e.target.value.replace(/[^0-9,]*/g, '');
                                handleCPF(cpf, values, setErrors, setValues);
                              }}
                              placeholder="___.___.___-__"
                            />
                          )}
                        </Field>
                      </FormControl>
                      <div style={{ display: values.type === 'Admin' ? 'none' : 'block' }}>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.carteira_motorista}
                          field="carteira_motorista"
                          label={t('screens:usuario.data.carteira_motorista')}
                          required
                        >
                          <Field name="carteira_motorista">
                            {({ field }) => (
                              <MaskedInput
                                mask="111.111.111.11"
                                {...field}
                                id="carteira_motorista"
                                placeholder="___.___.___.__"
                              />
                            )}
                          </Field>
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.telefone}
                          field="addresses.telefone"
                          label={t('screens:usuario.data.phone')}
                          required
                        >
                          <TelefoneBrasileiroInput
                            temDDD
                            value={telefone}
                            onChange={(event) => setTelefone(event.target.value)}
                            style={{ height: '30px', borderColor: 'lightGrey', borderWidth: '1px' }}
                          />
                        </FormControl>
                      </div>
                      <FormActions>
                        <Can permission="@usuario/edit">
                          <Button style={{ marginTop: '20px' }} type="submit" color="info">
                            Salvar
                          </Button>
                        </Can>
                        <Button style={{ marginTop: '20px' }} onClick={handleBack}>
                          Cancelar
                        </Button>
                      </FormActions>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>
    </DefaultLayout>
  );
}
