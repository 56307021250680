/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Modal, Spin, message } from 'antd';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import errorHandler from '~/Utils/errorHandler';

import api from '~/services/api';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';

export default function RolesForm({ visible, onClose, roleID }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(null);

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/roles/${roleID}`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/roles/${recordData.id}`, values);
      } else {
        await api.post('/roles', values);
      }
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (roleID) {
      fetchRecordData();
    } else {
      setRecordData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const roleSchema = Yup.object().shape({
    name: Yup.string().min(3).required(),
    slug: Yup.string().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={roleSchema}>
      {({ errors, isSubmitting, resetForm, submitForm }) => (
        <Form>
          <Input type="hidden" name="id" />
          <Spin spinning={loading || isSubmitting}>
            <Modal
              closable
              visible={visible}
              title={t('screens:roles.title')}
              afterClose={resetForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
              footer={<ModalFooter okColor="info" onOk={submitForm} onCancel={onClose} />}
            >
              <Row>
                <FormControl
                  label={t('screens:roles.data.name')}
                  cols={{ xs: 12 }}
                  field="name"
                  error={errors.name}
                  required
                >
                  <Input name="name" />
                </FormControl>
                <FormControl
                  label={t('screens:roles.data.slug')}
                  cols={{ xs: 12 }}
                  field="slug"
                  error={errors.slug}
                  required
                >
                  <Input name="slug" />
                </FormControl>
              </Row>
            </Modal>
          </Spin>
        </Form>
      )}
    </Formik>
  );
}
