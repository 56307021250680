/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Modal, Popconfirm, message, Switch, Popover, Input, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaInfoCircle, FaClipboardCheck } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Box from '~/components/Box';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { TableActions, Table } from '~/components/Table';
import Pagination from '~/components/Pagination';
import VeiculoForm from './forms';
import Pesquisa from './pesquisa';
import './styles.css';

export default function Veiculos() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState({});
  const [searchTerm, setSearchTerm] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [valuesEdit, setValuesEdit] = useState({});
  const [typeUser, setTypeUser] = useState();
  const [vistoria, setVistoria] = useState(false);
  const [km, setKm] = useState();

  const fetchRecords = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('/veiculos', {
        params: {
          placa: searchTerm.placa,
          modelo: searchTerm.modelo,
          periodo: searchTerm.periodo,
        },
      });

      if (data.data) {
        setRecordData(data.data);
        setMeta(data.meta);
      } else {
        setRecordData([data]);
      }
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  const changeDisp = async (record) => {
    setIsLoading(true);
    try {
      record.disponivel = !record.disponivel;
      await api.put(`/veiculos/${record.id}`, record);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
    fetchRecords();
  };

  const handleVistoria = async (record) => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/veiculos/vistoriado/${record.id}`, {
        novo_km: km,
      });
      setVistoria(false);
      changeDisp(record);
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  const handleEdit = (record) => {
    try {
      setValuesEdit(record);
    } catch (error) {
      errorHandler(error);
    }
    setOpen(true);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await api.delete(`veiculos/${id}`);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      fetchRecords();
    }
  };

  const handleChangePage = async (page) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/veiculos?page=${page}`, {
        params: {
          placa: searchTerm.placa,
          modelo: searchTerm.modelo,
        },
      });
      const { data } = response;
      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setValuesEdit({});
  };

  const veiculoAddHandler = async (values) => {
    try {
      if (values.id) {
        await api.put(`/veiculos/${values.id}`, values);
      } else {
        const { data } = await api.get(`/veiculos/placa/${values.placa}`);
        if (data === false) {
          message.warning(t('messages:veiculoExist'));
          return;
        }
        await api.post('/veiculos', values);
        setIsLoading(true);
      }
      message.success(t('messages:success'));
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
    fetchRecords();
    handleCancel();
  };

  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('/getlogin');
      setTypeUser(data.type);
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRecords();
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const tableColumns = [
    {
      title: t('screens:veiculos.data.id'),
      dataIndex: 'id',
      align: 'left',
      key: 'id',
      responsive: ['sm', 'md', 'lg'],
    },
    {
      title: t('screens:veiculos.data.modelo'),
      dataIndex: 'modelo',
      align: 'left',
      key: 'modelo',
      responsive: ['sm'],
    },
    {
      title: t('screens:veiculos.data.placa'),
      dataIndex: 'placa',
      align: 'left',
      key: 'placa',
      responsive: ['sm', 'md', 'lg'],
    },
    {
      title: 'Veículos',
      dataIndex: 'placa',
      align: 'center',
      key: 'placa',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>{record.placa}</p>
          <p>
            <span style={{ color: 'gray' }}>{record.modelo}</span>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              {record.periodo_avaliacao === 1 ? (
                <Popconfirm
                  title={t('Você deseja colocar o veículo como indisponível para fazer a vistoria?')}
                  okText={t('messages:yes')}
                  onConfirm={() => {
                    changeDisp(record);
                  }}
                  cancelText={t('messages:no')}
                >
                  <Switch
                    size="small"
                    style={{ marginTop: '6px', marginRight: '12px' }}
                    name="active"
                    checked={record.disponivel}
                    onChange={() => changeDisp(record)}
                  />
                </Popconfirm>
              ) : (
                <Switch
                  size="small"
                  style={{ marginTop: '6px', marginRight: '12px' }}
                  name="active"
                  checked={record.disponivel}
                  onChange={() => changeDisp(record)}
                />
              )}

              {typeUser === 'Admin' && (
                <>
                  <Button
                    size="small"
                    title="Editar"
                    onClick={() => {
                      handleEdit(record);
                    }}
                  >
                    <FaPencilAlt style={{ color: 'green' }} />
                  </Button>
                  <Popconfirm
                    title={t('messages:confirmDelete')}
                    okText={t('messages:yes')}
                    onConfirm={() => {
                      handleDelete(record.id);
                    }}
                    cancelText={t('messages:no')}
                  >
                    <Button size="small" title="Excluir">
                      <FaRegTrashAlt style={{ color: 'red' }} />
                    </Button>
                  </Popconfirm>

                  {record.disponivel === 0 ? (
                    <>
                      <Button
                        size="small"
                        title="Vistoria completa"
                        disabled={!record.periodo_avaliacao === 1}
                        onClick={() => {
                          setVistoria(true);
                        }}
                      >
                        <FaClipboardCheck style={{ color: record.periodo_avaliacao === 1 ? 'green' : 'gray' }} />
                      </Button>
                      <Modal
                        closable
                        title="Completar vistoria"
                        visible={vistoria}
                        width={1000}
                        onCancel={() => setVistoria(false)}
                        onClose={() => setVistoria(false)}
                        footer={[]}
                      >
                        <>
                          <Input
                            name="novo_km"
                            style={{ width: '90%' }}
                            type="number"
                            onChange={(e) => setKm(e.target.value)}
                            value={km}
                            placeholder="Enter km"
                          />
                          <Button
                            type="primary"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                              handleVistoria(record);
                            }}
                          >
                            Fazer vistoria
                          </Button>
                        </>
                      </Modal>
                    </>
                  ) : (
                    <Popconfirm
                      title={t('Você deseja colocar o veículo como indisponível para fazer a vistoria?')}
                      okText={t('messages:yes')}
                      onConfirm={() => {
                        changeDisp(record);
                      }}
                      cancelText={t('messages:no')}
                    >
                      <Button size="small" title="Vistoria completa" disabled={!record.periodo_avaliacao === 1}>
                        <FaClipboardCheck style={{ color: record.periodo_avaliacao === 1 ? 'green' : 'gray' }} />
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}
              {record.obs_gerais && (
                <Popover content={<p>{record.obs_gerais}</p>} title="Observações" trigger="hover">
                  <FaInfoCircle style={{ marginTop: 5, marginLeft: 10, color: '#1890ff' }} />
                </Popover>
              )}
            </TableActions>
          </div>
        </div>
      ),
    },

    {
      title: t('screens:users.data.actions'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: '140px',
      responsive: ['sm'],
      render: (text, record) => (
        <TableActions>
          <Switch
            size="small"
            style={{ marginTop: '6px', marginRight: '12px' }}
            name="activ"
            checked={record.disponivel}
            onChange={() => changeDisp(record)}
          />

          {typeUser === 'Admin' && (
            <>
              <Button
                size="small"
                title="Editar"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                <FaPencilAlt style={{ color: 'green' }} />
              </Button>
              <Popconfirm
                title={t('messages:confirmDelete')}
                okText={t('messages:yes')}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
                cancelText={t('messages:no')}
              >
                <Button size="small" title="Excluir">
                  <FaRegTrashAlt style={{ color: 'red' }} />
                </Button>
              </Popconfirm>

              {record.disponivel === 0 ? (
                <>
                  <Button
                    size="small"
                    title="Vistoria completa"
                    disabled={!record.periodo_avaliacao === 1}
                    onClick={() => {
                      setVistoria(true);
                    }}
                  >
                    <FaClipboardCheck style={{ color: record.periodo_avaliacao === 1 ? 'green' : 'gray' }} />
                  </Button>
                  <Modal
                    closable
                    title="Completar vistoria"
                    visible={vistoria}
                    width={1000}
                    onCancel={() => setVistoria(false)}
                    onClose={() => setVistoria(false)}
                    footer={[]}
                  >
                    <>
                      <Input
                        name="novo_km"
                        style={{ width: '90%' }}
                        type="number"
                        onChange={(e) => setKm(e.target.value)}
                        value={km}
                        placeholder="Enter km"
                      />
                      <Button
                        type="primary"
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          handleVistoria(record);
                        }}
                      >
                        Fazer vistoria
                      </Button>
                    </>
                  </Modal>
                </>
              ) : (
                <Popconfirm
                  title={t('Você deseja colocar o veículo como indisponível para fazer a vistoria?')}
                  okText={t('messages:yes')}
                  onConfirm={() => {
                    changeDisp(record);
                  }}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Vistoria completa" disabled={!record.periodo_avaliacao === 1}>
                    <FaClipboardCheck style={{ color: record.periodo_avaliacao === 1 ? 'green' : 'gray' }} />
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
          {record.obs_gerais && (
            <Popover content={<p>{record.obs_gerais}</p>} title="Observações" trigger="hover">
              <FaInfoCircle style={{ marginTop: 5, marginLeft: 10, color: '#1890ff' }} />
            </Popover>
          )}
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:veiculos.title')}>
        {typeUser === 'Admin' && (
          <Button onClick={showModal} loading={isLoading} color="info">
            <FaPlus />
            {t('screens:veiculos.btnNew')}
          </Button>
        )}
        <VeiculoForm
          inicialValues={valuesEdit}
          handleCancel={handleCancel}
          onAddVeiculo={veiculoAddHandler}
          visible={open}
          loading={isLoading}
        />
      </PageTitle>
      <Box>
        <Pesquisa onQuery={(value) => setSearchTerm(value)} />
      </Box>

      <Box>
        <Table pagination={false} rowKey="id" loading={isLoading} dataSource={recordData} columns={tableColumns} />
        <Pagination
          onChange={(value) => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
