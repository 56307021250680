/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { isArray, isEmpty } from 'lodash';

import { FaClipboardList } from 'react-icons/fa';
import api from '~/services/api';
import Row from '~/components/Row';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { decrypt } from '~/Utils/index';
import { Box } from './styles';

export default function Home() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saidas, setSaidas] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [veiculos, setVeiculos] = useState([]);
  const [meta, setMeta] = useState({});
  const [meta2, setMeta2] = useState({});
  const [meta3, setMeta3] = useState({});
  const [mobilizados, setMobilizados] = useState([]);

  const [y, setY] = useState('');
  const user =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchSaidas = async () => {
    setLoading(true);
    try {
      let response = [];
      if (user.type === 'Admin') {
        response = await api.get(`/dashboard/entradas?page=1`);
      } else {
        response = await api.get(`/controle?page=1`);
      }
      const { data } = response;

      if (data === '') {
        setLoading(false);
        return null;
      }
      setSaidas(data.data);
      setMeta2(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    return null;
  };
  const handleChangePage2 = async (page) => {
    setLoading(true);
    try {
      let response = [];
      if (user.type === 'Admin') {
        response = await api.get(`/dashboard/entradas?page=${page}`);
      } else {
        response = await api.get(`/controle?page=${page}`);
      }
      const { data } = response;
      setSaidas(data.data);
      setMeta2(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      let response = [];

      if (user.type === 'Admin') {
        response = await api.get(`/dashboard/disponivel?page=1`);
      } else {
        response = await api.get('/veiculos?page=1');
      }
      const { data } = response;
      if (!data.data) {
        setVeiculos([data]);
        setY(`Veículo mobilizado por ${user.name} `);
      } else {
        setVeiculos(data.data);
        setMeta(data.meta);
        setY('Veículos disponíveis');
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      let response = [];
      if (user.type === 'Admin') {
        response = await api.get(`/dashboard/disponivel?page=${page}`);
      } else {
        response = await api.get(`/veiculos?page=${page}`);
      }
      const { data } = response;
      setVeiculos(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchEmUso = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/dashboard/emUso');
      if (!isEmpty(data)) {
        setRecordData([data]);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchMobilizados = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/dashboard/mobilizados?page=1');
      if (!isArray(data.data)) {
        setMobilizados([data.data]);
      } else {
        setMobilizados(data.data);
      }
      setMeta3(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage3 = async (page) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/dashboard/mobilizados?page=${page}`);
      if (!isArray(data.data)) {
        setMobilizados([data.data]);
      } else {
        setMobilizados(data.data);
      }
      setMeta3(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {}, 1000);

      fetchSaidas();
      fetchRecords();
      if (user.type === 'Admin') {
        fetchEmUso();
        fetchMobilizados();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name]);

  const tableColumns = [
    {
      title: t('screens:relatorios.data.date'),
      dataIndex: 'saida',
      key: 'saida',
      width: '30%',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:relatorios.data.motorista'),
      dataIndex: 'checklist_users',
      key: 'motorista',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      hidden: !(user.type === 'Admin'),
    },
    {
      title: t('screens:relatorios.data.veiculo'),
      dataIndex: 'veiculos_placa',
      key: 'veiculos',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Entrada'),
      dataIndex: 'entrada',
      key: 'nome',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      hidden: user.type === 'Admin',
    },
    {
      // title: 'Últimas desmobilizações / Mobilizações',
      dataIndex: 'saida',
      align: 'center',
      key: 'saida',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>
            <span style={{ color: 'gray' }}>{record.saida}</span>
          </p>
          <p>{record.checklist_users}</p>
          {/* span with color gray */}
          <p>
            <span style={{ color: 'gray' }}>{record.veiculos_placa}</span>
          </p>
        </div>
      ),
    },
  ].filter((item) => item.hidden !== true);

  const tableColumnsVeiculos = [
    {
      title: t('screens:veiculos.data.id'),
      dataIndex: 'id',
      key: 'ids',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl']
    },
    {
      title: t('screens:veiculos.data.placa'),
      dataIndex: 'placa',
      key: 'placa',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl']
    },
    {
      title: t('screens:veiculos.data.modelo'),
      dataIndex: 'modelo',
      key: 'modelo',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl']
    },
    {
      // title: 'Veículos disponíveis',
      dataIndex: 'placa',
      align: 'center',
      key: 'placa',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>{record.placa}</p>
          {/* span with color gray */}
          <p>
            <span style={{ color: 'gray' }}>{record.modelo}</span>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <div style={{ textAlign: 'center' }}>
                <Link to={`/controle/${record.id}`} title={record.disponivel ? 'Mobilizar' : 'Desmobilizar'}>
                  {record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}
                  <FaClipboardList />
                </Link>
              </div>
            </TableActions>
          </div>
        </div>
      ),
    },
    {
      title: t('screens:users.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '135px',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <TableActions>
          <div style={{ textAlign: 'center' }}>
            <Link to={`/controle/${record.id}`} title={record.disponivel ? 'Mobilizar' : 'Desmobilizar'}>
              {record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}
              <FaClipboardList />
            </Link>
          </div>
        </TableActions>
      ),
    },
  ];
  const tableColumnsMob = [
    {
      title: t('screens:veiculos.data.id'),
      dataIndex: 'id',
      key: 'ids',
      responsive: ['sm', 'md', 'lg'],
    },
    {
      title: t('screens:relatorios.data.motorista'),
      dataIndex: 'checklist_users',
      key: 'nome',
      responsive: ['sm', 'md', 'lg'],
    },
    {
      title: t('screens:veiculos.data.placa'),
      dataIndex: 'veiculos_placa',
      key: 'placa',
      responsive: ['md', 'lg'],
    },

    {
      title: t('screens:veiculos.data.modelo'),
      dataIndex: 'veiculos',
      key: 'modelo',
      responsive: ['lg', 'xl', 'xxl'],
      render: ({ modelo }) => <p>{modelo}</p>,
    },

    {
      // title: 'Veículos mobilizados',
      dataIndex: 'checklist_users',
      align: 'center',
      key: 'checklist_users',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>{record.checklist_users}</p>
          {/* span with color gray */}
          <p>
            <span style={{ color: 'gray' }}>{record.veiculos_placa}</span>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <div style={{ textAlign: 'center' }}>
                <Link to={`/controle/${record.id}`} title={record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}>
                  {record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}
                  <FaClipboardList />
                </Link>
              </div>
            </TableActions>
          </div>
        </div>
      ),
    },

    {
      title: t('screens:users.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '135px',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <TableActions>
          <div style={{ textAlign: 'center' }}>
            <Link to={`/controle/${record.id}`} title={record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}>
              {record.disponivel ? 'Mobilizar  ' : 'Desmobilizar  '}
              <FaClipboardList />
            </Link>
          </div>
        </TableActions>
      ),
    },
  ];

  return (
    <DefautLayout>
      {user.type === 'Admin' && (
        <>
          <PageTitle title={`Veículo mobilizado por ${user.name}`} />
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Box>
                <Table
                  pagination={false}
                  rowKey="id2"
                  loading={loading}
                  dataSource={recordData}
                  columns={tableColumnsVeiculos}
                />
              </Box>
            </Col>
          </Row>
          <br />
          <br />

          <PageTitle title="Veículos mobilizados" />
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Box>
                <Table
                  pagination={false}
                  rowKey="id"
                  loading={loading}
                  dataSource={mobilizados}
                  columns={tableColumnsMob}
                />
                <Pagination
                  onChange={(value) => {
                    handleChangePage3(value);
                  }}
                  meta={meta3}
                />
              </Box>
            </Col>
          </Row>
          <br />
          <br />
        </>
      )}

      <PageTitle title={user.type === 'Admin' ? 'Veículos disponíveis' : y} />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Box>
            <Table
              pagination={false}
              rowKey="id2"
              loading={loading}
              dataSource={veiculos}
              columns={tableColumnsVeiculos}
            />
            <Pagination
              onChange={(value) => {
                handleChangePage(value);
              }}
              meta={meta}
            />
          </Box>
        </Col>
      </Row>

      <br />
      <br />

      <PageTitle title={user.type === 'Admin' ? 'Últimas desmobilizações' : 'Mobilizações'} />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Box>
            <Table pagination={false} rowKey="id" loading={loading} dataSource={saidas} columns={tableColumns} />
            <Pagination
              onChange={(value) => {
                handleChangePage2(value);
              }}
              meta={meta2}
            />
          </Box>
        </Col>
      </Row>
    </DefautLayout>
  );
}
